import { Component, OnDestroy } from '@angular/core';
import { TranslatePipe } from '../../pipe/translate.pipe';
import { RecaptchaV3Module, ReCaptchaV3Service } from 'ng-recaptcha';
import { ContactService } from '../../services/contact.service';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { NgIf } from '@angular/common';
import { SeoService } from '../../services/seo.service';
import { I18nService } from '../../services/i18n.service';
import { Subscription } from 'rxjs';


export class ContactInputData
{
    data: ContactInput = new ContactInput();
    token: string = '';
}

export class ContactInput
{
    lastName: string = '';
    firstName: string = '';
    company: string = '';
    mail: string = '';
    phone: string = '';
    message: string = '';
    place: string = '';
    source: string = '';
}

@Component({
  selector: 'app-contact',
  standalone: true,
  imports: [TranslatePipe, RecaptchaV3Module, ReactiveFormsModule, NgIf],
  templateUrl: './contact.component.html',
  styleUrl: './contact.component.scss'
})
export class ContactComponent implements OnDestroy
{
  capchaValid: boolean = false;
  capchaToken: string = '';
  
  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }
  
  form: FormGroup;

  submitted = false;
  messageCreated: boolean = false;

  private lgSub: Subscription;

  constructor(private http: HttpClient, private recaptchaV3Service: ReCaptchaV3Service, private contactService: ContactService, private formBuilder: FormBuilder, private seoService: SeoService, private i18nService: I18nService)
  {
    this.lgSub = this.i18nService.onLanguageChange().subscribe(() => {
      this.seoService.setPage("contact");
    });

    this.form = this.formBuilder.group(
    {
      name: ['', [Validators.required]],
      surname: [''],
      company: ['', [Validators.required]],
      mail: ['', [Validators.required, Validators.email]],
      phone: [''],
      message: ['', [Validators.required]],
    });
  }

  ngOnDestroy(): void
  {
    this.lgSub.unsubscribe();
  }
  
  public executeImportantAction(): void 
  {
    this.recaptchaV3Service.execute('importantAction').subscribe((token) => 
    {
      this.capchaValid = true;
      this.capchaToken = token;
    });
  }

  public onContactSubmit(): void
  {
    this.submitted = true;

    if (!this.form.invalid && this.capchaValid)
    {
      this.submitted = false;
      
      var body: ContactInputData = { 
          data: {
            lastName: this.form.get("name")?.value, 
            firstName: this.form.get("surname")?.value, 
            mail: this.form.get("mail")?.value, 
            company: this.form.get("company")?.value,
            phone: this.form.get("phone")?.value,
            message: this.form.get("message")?.value,
            place: "None",
            source: 'FROM_Contact'
        },
        token: this.capchaToken
      };

      this.http.post<ContactInputData>(environment.backendUrl + "/api/leads", body).subscribe(() => 
      {
        this.messageCreated = true;
      });
    }
  }
}
