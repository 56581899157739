import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslatePipe } from '../../pipe/translate.pipe';
import { CircleStatComponent } from '../../component/circle-stat/circle-stat.component';
import { Router } from '@angular/router';
import { Route } from '../../interface/route';
import { CircleContactComponent } from '../../component/circle-contact/circle-contact.component';
import { MethodologyComponent } from '../../component/methodology/methodology.component';

import AOS from "aos";
import { BubbleBackgroundComponent } from '../../component/bubble-background/bubble-background.component';
import { BackToTopComponent } from '../../component/back-to-top/back-to-top.component';
import { SeoService } from '../../services/seo.service';
import { I18nService } from '../../services/i18n.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-agency',
  standalone: true,
  imports: [TranslatePipe, CircleStatComponent, CircleContactComponent, MethodologyComponent, BubbleBackgroundComponent, BackToTopComponent],
  templateUrl: './agency.component.html',
  styleUrl: './agency.component.scss'
})
export class AgencyComponent implements OnInit, OnDestroy
{
  @ViewChild('teaserPlayer', { static: false }) teaserPlayer!: ElementRef<HTMLVideoElement>;

  teaserLink = "";

  private lgSub: Subscription;

  constructor(private seoService: SeoService, private router: Router, private i18nService: I18nService)
  {
    this.lgSub = this.i18nService.onLanguageChange().subscribe(() => {
      this.teaserLink = "../../../assets/videos/teaser_" + this.i18nService.Language + ".mp4";
      if (this.teaserPlayer)
      {
        const videoElem = this.teaserPlayer.nativeElement;
        videoElem.currentTime = 0;
        videoElem.load();
        videoElem.play();
      }
      
      this.seoService.setPage("agency");
    });
  }
  
  ngOnInit(): void
  {
    AOS.init();
  }

  ngOnDestroy(): void 
  {
    this.lgSub.unsubscribe();
  }

  onGotoPortfolioClick(): void
  {
    this.router.navigate([Route.Porfolio]);
  }
}
