
<section *ngIf="article" class="justify-content-start bg-dark">

  <div class="d-flex flex-column text-light">

    <div class="d-flex flex-row align-items-center cursor-pointer mt-6" (click)="onBackClick()">  
      <img class="arrow-back active me-3" src="../../../assets/images/pictos/arrow.svg"  />
      <div class="typo-project-backbtn">{{ 'article.back_to_articles' | translate }}</div>
    </div>

    <h2 class="mt-4">{{ article.attributes.name }}</h2>
    <img [src]="backendUrl + article.attributes.preview.data.attributes.url" class="d-block single-article" alt="">

    <div class="container py-4">
      <markdown class="markdown">{{ article.attributes.content }}</markdown>
      <div class="mt-2">{{ 'article.published-at' | translate }} {{ article.attributes.publishedAt | date: 'mediumDate': '' : dateLang }}</div>
    </div>

  </div>

</section>