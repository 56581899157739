
<div class="d-flex flex-column project">
  
  <div class="d-flex flex-column align-items-center mt-3">
    <img class="pic" [ngClass]="{ 'latest': useLatestDesign }" [src]="backendUrl + getFirstImageUrl(project)" />
  </div>

  <div class="d-flex flex-row justify-content-between mt-3 header">
    <div class="color-primary">{{ project.attributes.company }}</div>
    <div class="ms-3 fst-italic text-nowrap" *ngIf="project.attributes.categories.data.length > 0">{{ project.attributes.categories.data[0].attributes.name }}</div>
  </div>

  <div class="description mt-2">{{ project.attributes.name }}</div>

  <img class="eye mt-3" src="../../../assets/images/pictos/eye.svg" />

  <hr class="mt-2">
  
</div>