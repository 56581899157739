import { Component } from '@angular/core';
import { BubbleBackgroundComponent } from '../../component/bubble-background/bubble-background.component';
import { TranslatePipe } from '../../pipe/translate.pipe';
import { AbstractControl, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { RecaptchaV3Module, ReCaptchaV3Service } from 'ng-recaptcha';
import { SeoService } from '../../services/seo.service';
import { I18nService } from '../../services/i18n.service';
import { environment } from '../../../environments/environment';
import { NgIf } from '@angular/common';

export class TotaraInputData
{
    data: TotaraInput = new TotaraInput();
    token: string = '';
}

export class TotaraInput
{
    lastName: string = '';
    firstName: string = '';
    company: string = '';
    mail: string = '';
    source: string = '';
}

@Component({
  selector: 'app-totara',
  standalone: true,
  imports: [TranslatePipe, BubbleBackgroundComponent, ReactiveFormsModule, NgIf, RecaptchaV3Module],
  templateUrl: './totara.component.html',
  styleUrl: './totara.component.scss'
})
export class TotaraComponent {
  capchaValid: boolean = false;
  capchaToken: string = '';
  
  get f(): { [key: string]: AbstractControl } { return this.form.controls; }
  
  form: FormGroup;

  submitted = false;
  leadMessageBackend: string = 'ERROR';
  leadGotResponse: boolean = false;
  leadSend: boolean = false;

  private lgSub: Subscription;
  
  constructor(private http: HttpClient, private recaptchaV3Service: ReCaptchaV3Service, private formBuilder: FormBuilder, private seoService: SeoService, private i18nService: I18nService)
  {
    this.lgSub = this.i18nService.onLanguageChange().subscribe(() => {
      this.seoService.setPage("totara");
    });
    
    this.form = this.formBuilder.group(
    {
      name: ['', [Validators.required]],
      surname: [''],
      company: ['', [Validators.required]],
      mail: ['', [Validators.required, Validators.email]]
    });
  }

  ngOnDestroy(): void
  {
    this.lgSub.unsubscribe();
  }
  
  public executeImportantAction(): void 
  {
    this.recaptchaV3Service.execute('importantAction').subscribe((token) => 
    {
      this.capchaValid = true;
      this.capchaToken = token;
    });
  }

  public onNewsletterSubmit(): void
  {
    this.submitted = true;

    if (!this.form.invalid && this.capchaValid)
    {
      this.submitted = false;
      this.leadSend = true;
      
      var body: TotaraInputData = { 
          data: {
            lastName: this.form.get("name")?.value, 
            firstName: this.form.get("surname")?.value, 
            mail: this.form.get("mail")?.value, 
            company: this.form.get("company")?.value,
            source: 'FROM_Totara'
        },
        token: this.capchaToken
      };

      this.http.post<TotaraInputData>(environment.backendUrl + "/api/leads", body).subscribe((response: any) => 
      {
        this.leadGotResponse = true;
        if (response.data.attributes.data.length > 0)
        {
          if (response.data.attributes.data[0].code == "SUCCESS")
          {
            this.leadMessageBackend = 'global.totara_sent';
          }
          else if  (response.data.attributes.data[0].code == "DUPLICATE_DATA")
          {
            this.leadMessageBackend = 'global.totara_duplicate';
          }
          else
          {
            this.leadMessageBackend = 'global.totara_error';
          }
        }
      });
    }
  }
}
