import { Routes } from '@angular/router';
import { Route } from './interface/route';
import { AgencyComponent } from './page/agency/agency.component';
import { ContactComponent } from './page/contact/contact.component';
import { HomeComponent } from './page/home/home.component';
import { PortfolioComponent } from './page/portfolio/portfolio.component';
import { PagenotfoundComponent } from './page/pagenotfound/pagenotfound.component';
import { ServicesComponent } from './page/services/services.component';
import { JoinComponent } from './page/join/join.component';
import { SingleProjectComponent } from './page/single-project/single-project.component';
import { SingleArticleComponent } from './page/single-article/single-article.component';
import { ArticlesComponent } from './page/articles/articles.component';
import { CookiepolicyComponent } from './page/cookiepolicy/cookiepolicy.component';
import { NewsletterComponent } from './page/newsletter/newsletter.component';
import { OurplatformsComponent } from './page/ourplatforms/ourplatforms.component';
import { LegalNoticeComponent } from './page/legal-notice/legal-notice.component';
import { TotaraComponent } from './page/totara/totara.component';

export const routes: Routes = [
    { path: '', component: HomeComponent, pathMatch: 'full' },
    
    { path: Route.Agency, component: AgencyComponent },
    { path: Route.OurPlatforms, component: OurplatformsComponent },
    { path: Route.Porfolio, component: PortfolioComponent },
    { path: Route.Services, component: ServicesComponent },
    { path: Route.Resources, component: ArticlesComponent },
    { path: Route.Totara, component: TotaraComponent },
    
    { path: Route.Project + "/:id", component: SingleProjectComponent },
    { path: Route.Resource + "/:id", component: SingleArticleComponent },

    { path: Route.Join, component: JoinComponent },
    { path: Route.Newsletter, component: NewsletterComponent },
    { path: Route.Contact, component: ContactComponent },
    { path: Route.CookiePolicy, component: CookiepolicyComponent },
    { path: Route.LegalNotice, component: LegalNoticeComponent },

    { path: '**', component: PagenotfoundComponent }
];
