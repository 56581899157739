
<section class="bg-dark">

  <div class="d-flex flex-column mt-6">
    <h2>{{ 'newsletter.title' | translate }}</h2>

    <div class="row mt-4">

      <div class="col-12 col-lg-6">
        <form *ngIf="!leadSend" class="d-flex flex-column justify-content-around mb-4" [formGroup]="form" (ngSubmit)="onNewsletterSubmit()">
          <div class="input-line mb-2">
            <!-- Nom -->
            <div class="flex-grow-1">
              <input formControlName="name" type="text" [placeholder]="'global.fields.name' | translate" />
              @if (submitted && f['name'].errors) {
                @if (f['name'].errors['required']) {
                <div class="typo-error">{{ 'global.name_required' | translate }}</div>
                }
              }
            </div>
            <!-- Prenom -->
            <div class="flex-grow-1">
              <input formControlName="surname"  type="text" [placeholder]="'global.fields.surname' | translate" />
            </div>
          </div>
          
          <div class="input-line mb-2">
            <!-- Societe -->
            <div class="flex-grow-1">
              <input formControlName="company" type="text" [placeholder]="'global.fields.company' | translate" />
              @if (submitted && f['company'].errors) {
                @if (f['company'].errors['required']) {
                <div class="typo-error">{{ 'global.company_required' | translate }}</div>
                }
              }
            </div>
            <!-- Email -->
            <div class="flex-grow-1">
              <input formControlName="mail" type="text" [placeholder]="'global.fields.mail' | translate" />
              @if (submitted && f['mail'].errors) {
                <div class="typo-error">
                    @if (f['mail'].errors['required']) {
                    <div>{{ 'global.email_required' | translate }}</div>
                    }
                    @if (f['mail'].errors['email']) {
                    <div>{{ 'global.email_invalid' | translate }}</div>
                    }
                </div>
              }
            </div>
          </div>
                
          <div class="mb-2">
            <div class="input-line">
              <!-- Captcha -->
              <div class="captcha d-flex flex-row align-items-center cursor-pointer" (click)="executeImportantAction()">
                <img [src]="capchaValid ? '../../../assets/images/pictos/check-square.svg' : '../../../assets/images/pictos/square.svg'" />
                <div class="ms-2">{{ "global.iamhuman" | translate }}</div>
              </div>

              <!-- Send -->
              <input class="input submit" type="submit" [value]="'global.send' | translate" />
            </div>
            
          @if (submitted && !capchaValid) {
            <div class="typo-error">{{ 'global.capcha_required' | translate }}</div>
          }
          
          </div>
        </form>

        <div *ngIf="leadSend" class="text-light mb-10">{{ leadMessageBackend | translate }}</div>
      </div>

      <div *ngIf="!leadSend" class="col-12 col-lg-5 offset-lg-1">
        <div class="text-light text-center">{{ 'newsletter.infos' | translate }}</div>
      </div>

    </div>

  </div>

</section>