import { NgClass, NgFor } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'brand-banner',
  standalone: true,
  imports: [NgFor, NgClass],
  templateUrl: './brand-banner.component.html',
  styleUrl: './brand-banner.component.scss'
})
export class BrandBannerComponent
{
  @Input() rightToLeft: boolean = false;
  @Input() urlBrand: string = '';
}
