
import { Injectable } from '@angular/core';
import gql from "graphql-tag";
import { I18nService } from './i18n.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectsService 
{
  constructor(private i18nService: I18nService) 
  {
  }

  public getProject() {
    const currentLanguage = this.i18nService.Language;
    return gql`
    query Project($id: ID!) {
      project(
              locale: "${currentLanguage}",
              id: $id
            ) {
        data {
          id,
          attributes {
            name,
            description,
            needs,
            left_content,
            right_content,
            isLarge,
            preview {
              data {
                id,
                attributes {
                  url
                }
              }
            },
            carousel {
              data {
                id,
                attributes {
                  mime,
                  url
                }
              }
            },
            company,
            activities {
              data {
                id, 
                attributes{name}
              }
            },
            sectors {
              data {
                id, 
                attributes{name}
              }
            },
            categories{
              data {
                id, 
                attributes{name}
              }
            }
          }
        }
      }
    }
    `;
  }

  public getProjects() {
    const currentLanguage = this.i18nService.Language;
    return gql`
    query Projects($category: String, $activity: String, $sector: String, $id: ID, $limit: Int, $sort: [String]) {
      projects(
              locale: "${currentLanguage}",
              pagination: {limit: $limit},
              sort: $sort,
              filters: {
                id: {
                  notContains: $id
                }
                activities: {
                  name: {
                    contains: $activity
                  }
                }
                sectors: {
                  name: {
                    contains: $sector
                  }
                }
                categories: {
                  name: {
                    contains: $category
                  }
                }
              }) {
        data {
          id,
          attributes {
            name,
            description,
            order,
            isLarge,
            preview {
              data {
                id,
                attributes {
                  url,
                  height
                }
              }
            }
            company,
            activities 
              {data {
                id, 
                attributes{name}
              }
            },
            sectors {
              data {
                id, 
                attributes{name}
              }
            },
            categories {
              data {
                id, 
                attributes{name}
              }
            }
          }
        }
      }
    }
    `;
  }
}
