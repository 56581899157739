<!-- Header -->
<section class="bg-dark justify-content-center position-relative">

  <bubble-background></bubble-background>

  <div class="typo-nav-title mt-4">{{ 'menu.services.title_nav' | translate }}</div>
  <h2 class="mt-2">{{ 'services.title' | translate }}</h2>
  <div class="text-light w-adj mt-3">{{ 'services.subtitle' | translate }}</div>

</section>

<!-- Conseils -->
<section class="bg-light" id="section1">

  <div class="d-flex flex-column">
    <div data-aos="fade-down" class="typo-rp-bold color-secondary">{{ 'services.section_1.title' | translate }}</div>
    <h2 data-aos="fade-right" class="color-secondary mt-2">{{ 'services.section_1.subtitle' | translate }}<span
        class="line secondary"></span></h2>
    <div data-aos="zoom-in" class="w-adj mt-1" [innerHTML]="'services.section_1.description' | translate"></div>
  </div>

</section>

<!-- Création sur mesure -->
<section class="section bg-dark" id="section2">

  <bubble-background></bubble-background>

  <div class="d-flex flex-column">
    <div data-aos="fade-down" class="text-light typo-rp-bold">{{ 'services.section_2.title' | translate }}</div>
    <h2 data-aos="fade-right" class="mt-2">{{ 'services.section_2.subtitle' | translate }}<span class="line"></span>
    </h2>
    <div data-aos="zoom-in" class="digital w-adj text-light mt-1">{{ 'services.section_2.description' | translate }}
    </div>
  </div>

</section>

<!-- Savoir faire -->
<section class="section bg-light" id="section3">

  <div class="d-flex flex-column">

    <div data-aos="fade-down" class="typo-rp-bold color-secondary">{{ 'services.section_3.title' | translate }}</div>
    <h2 data-aos="fade-right" class="color-secondary mt-2">{{ 'services.section_3.subtitle' | translate }}<span
        class="line secondary"></span></h2>

    <solution trigger="#section3"></solution>

  </div>

</section>

<!-- Formation & Formateur -->
<section class="bg-dark" id="section4">

  <bubble-background></bubble-background>

  <div class="d-flex flex-column">
    <div data-aos="fade-down" class="text-light typo-rp-bold">{{ 'services.section_4.title' | translate }}</div>
    <h2 data-aos="fade-right" class="mt-2">{{ 'services.section_4.subtitle' | translate }}<span class="line"></span>
    </h2>
    <div data-aos="zoom-in" class="w-adj text-light mt-1">{{ 'services.section_4.description' | translate }}</div>
  </div>

</section>

<!-- Catalogue -->
<section class="bg-light" id="section5">

  <div class="d-flex flex-column">

    <div data-aos="fade-down" class="typo-rp-bold color-secondary">{{ 'services.section_5.title' | translate }}</div>
    <h2 data-aos="fade-right" class="color-secondary mt-2">{{ 'services.section_5.subtitle' | translate }}<span
        class="line secondary"></span></h2>
    <div data-aos="zoom-in" class="mt-1">{{ 'services.section_5.description' | translate }}</div>

    <div class="mt-6">{{ 'services.section_5.ourformationelearning' | translate }}</div>

    <formation [title]="'services.section_5.formation_1.title' | translate"
      [description]="'services.section_5.formation_1.description' | translate"
      [objectives]="'services.section_5.formation_1.objectives' | translate"
      [target]="'services.section_5.formation_1.target' | translate"
      [duration]="'services.section_5.formation_1.duration' | translate"
      urlImage="../../../assets/formation/IMG_Formation_IPM.png"
      [urlProgram]="i18nService.Language == 'fr' ? '../../../assets/formation/Programme_IPM.pdf' : '../../../assets/formation/Program_IPM_En.pdf'">
    </formation>

    <formation [title]="'services.section_5.formation_4.title' | translate"
      [description]="'services.section_5.formation_4.description' | translate"
      [objectives]="'services.section_5.formation_4.objectives' | translate"
      [target]="'services.section_5.formation_4.target' | translate"
      [duration]="'services.section_5.formation_4.duration' | translate"
      urlImage="../../../assets/formation/IMG_Formation_Cursus-e-learning.png"
      [urlProgram]="i18nService.Language == 'fr' ? '../../../assets/formation/Programme_premier_cursus.pdf' : '../../../assets/formation/Program_FirstCourse_En.pdf'"></formation>

    <div class="mt-6">{{ 'services.section_5.ourformationeproduct' | translate }}</div>

    <formation [title]="'services.section_5.formation_2.title' | translate"
      [description]="'services.section_5.formation_2.description' | translate"
      [objectives]="'services.section_5.formation_2.objectives' | translate"
      [target]="'services.section_5.formation_2.target' | translate"
      [duration]="'services.section_5.formation_2.duration' | translate"
      urlImage="../../../assets/formation/IMG_Formation_Storyline.png"
      [urlProgram]="i18nService.Language == 'fr' ? '../../../assets/formation/Programme_Storyline.pdf' : '../../../assets/formation/Program_Storyline_En.pdf'"></formation>

    <formation [title]="'services.section_5.formation_3.title' | translate"
      [description]="'services.section_5.formation_3.description' | translate"
      [objectives]="'services.section_5.formation_3.objectives' | translate"
      [target]="'services.section_5.formation_3.target' | translate"
      [duration]="'services.section_5.formation_3.duration' | translate"
      urlImage="../../../assets/formation/IMG_Formation_Rise.png"
      [urlProgram]="i18nService.Language == 'fr' ? '../../../assets/formation/Programme_Rise.pdf' : '../../../assets/formation/Program_Rise_En.pdf'"></formation>

  </div>
</section>

<!-- Cercle contact -->
<section class="bg-dark">
  <div class="d-flex flex-row justify-content-center align-items-center flex-grow-1">
    <circle-contact [useDarkTextCircle]="false">
      <div class="typo-circle-title ">{{ 'home.section_6.title' | translate }}</div>
      <div class="typo-circle-contactus mt-2">{{ 'home.section_6.contact_us' | translate }}</div>
    </circle-contact>
  </div>

  <!-- Back to top-->
  <back-to-top [useBlue]="false"></back-to-top>
</section>