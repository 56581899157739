import { Component } from '@angular/core';
import { TranslatePipe } from '../../pipe/translate.pipe';
import { BubbleBackgroundComponent } from '../../component/bubble-background/bubble-background.component';
import { Router } from '@angular/router';
import { Route } from '../../interface/route';

@Component({
  selector: 'app-pagenotfound',
  standalone: true,
  imports: [TranslatePipe, BubbleBackgroundComponent],
  templateUrl: './pagenotfound.component.html',
  styleUrl: './pagenotfound.component.scss'
})
export class PagenotfoundComponent
{  
  constructor(private router: Router)
  {
  }
  
  onBackClick(): void
  {
    this.router.navigate([Route.Base]);
  }
}
