

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Translation } from '../interface/translation';

@Injectable({
    providedIn: 'root'
})
export class I18nService {
    public readonly FALLBACK_LANGUAGE: string = "fr";

    public get Language(): string {
        return this.language.value;
    }

    public onLanguageChange(): BehaviorSubject<string> {
        return this.language;
    }

    translationTexts: Translation | undefined;

    private language: BehaviorSubject<string> = new BehaviorSubject(this.FALLBACK_LANGUAGE);

    constructor(private http: HttpClient) 
    {
    }

    public loadDefaultLanguage(): Promise<void>
    {
        return this.loadLanguage(this.FALLBACK_LANGUAGE);
    }

    public toggleLanguage(): void
    {
        if (this.Language === 'fr')
        {
            this.loadLanguage('en');
        }
        else
        {
            this.loadLanguage('fr');
        }
    }

    private loadLanguage(locale: string | null): Promise<void>
    {
        return new Promise(async (resolve, reject) =>
        {
            const selectedLocale = locale ? locale : this.FALLBACK_LANGUAGE;
            this.http.get<Translation | undefined>('../assets/i18n/' + selectedLocale + '.json').subscribe((result) =>
            {
                this.translationTexts = result;
                
                this.language.next(selectedLocale);
                resolve();
            });
        });
    }

    public getText(keysName: string): string 
    {
        let txtFound = keysName + " notfound";
        if (this.translationTexts) {
            
            const keys = keysName.split('.');
            let currentValue: any = this.translationTexts;
            for (const key of keys) {
                if (currentValue.hasOwnProperty(key)) {
                    currentValue = currentValue[key];
                } else {
                    return txtFound;
                }
            }
            if (typeof currentValue === 'string') {
                return currentValue;
            }
        }
        return txtFound;
    }
}
