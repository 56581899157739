import { Component, Input } from '@angular/core';
import { Article } from '../../interface/article';
import { TranslatePipe } from '../../pipe/translate.pipe';
import { environment } from '../../../environments/environment';
import { NgIf } from '@angular/common';
import { MarkdownModule } from 'ngx-markdown';

@Component({
  selector: 'article',
  standalone: true,
  imports: [TranslatePipe, NgIf, MarkdownModule],
  templateUrl: './article.component.html',
  styleUrl: './article.component.scss'
})
export class ArticleComponent 
{
  backendUrl: string = environment.backendUrl;
  
  @Input() article!: Article;

  
}
