
import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslatePipe } from '../../pipe/translate.pipe';
import { CircleStatComponent } from '../../component/circle-stat/circle-stat.component';
import { NgFor } from '@angular/common';
import { Apollo } from 'apollo-angular';
import { ProjectsService } from '../../services/projects.service';
import { ProjectComponent } from '../../component/project/project.component';
import { Project } from '../../interface/project';
import { Article } from '../../interface/article';
import { ArticlesService } from '../../services/articles.service';
import { Router } from '@angular/router';
import { Route } from '../../interface/route';
import { BrandBannerComponent } from '../../component/brand-banner/brand-banner.component';
import { I18nService } from '../../services/i18n.service';
import { Subscription } from 'rxjs';
import { CircleContactComponent } from '../../component/circle-contact/circle-contact.component';

import AOS from "aos";
import { ArticleLatestComponent } from '../../component/article-latest/article-latest.component';
import { LatestService } from '../../services/latest.service';
import { BackToTopComponent } from "../../component/back-to-top/back-to-top.component";
import { SeoService } from '../../services/seo.service';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [NgFor, TranslatePipe, CircleStatComponent, CircleContactComponent, ProjectComponent, ArticleLatestComponent, BrandBannerComponent, BackToTopComponent, BackToTopComponent],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent implements OnInit, OnDestroy
{
  @ViewChild('teaserPlayer', { static: false }) teaserPlayer!: ElementRef<HTMLVideoElement>;

  lastProjects: Project[] = [];
  lastArticles: Article[] = [];

  teaserLink = "";
  videoUrl = '../../../assets/videos/brain.mp4';
  urlBrandsTop: string = "../../../assets/images/brands/firstLine.jpg";
  urlBrandsBtw: string = "../../../assets/images/brands/secondLine.jpg";

  loading : boolean = true;

  private lgSub: Subscription;

  constructor(private apollo: Apollo, private latestService: LatestService, private router: Router, private i18nService: I18nService, private seoService: SeoService)
  {
    this.lgSub = this.i18nService.onLanguageChange().subscribe(() => {
      this.teaserLink = "../../../assets/videos/teaser_" + this.i18nService.Language + ".mp4";
      
      if (this.teaserPlayer)
      {
        const videoElem = this.teaserPlayer.nativeElement;
        videoElem.currentTime = 0;
        videoElem.load();
        videoElem.play();
      }

      this.seoService.setPage("home");
      this.reloadPageElements();
    });
  }
  
  ngOnInit(): void
  {
    AOS.init();
  }

  ngOnDestroy(): void
  {
    this.lgSub.unsubscribe();
  }

  reloadPageElements() 
  {
    this.apollo.query({ query: this.latestService.getLatest() }).subscribe(({data, error}: any) => 
    {
      this.loading = false;
      this.lastProjects = data.latest.data.attributes.projects.data;
      this.lastArticles = data.latest.data.attributes.articles.data;
    },
    (err: any) => {
      console.log(err);
    });
  }

  onGotoAgencyClick(): void
  {
    this.router.navigate([Route.Agency]);
  }
  onGotoPortfolioClick(): void
  {
    this.router.navigate([Route.Porfolio]);
  }
  onGotoResourcesClick(): void
  {
    this.router.navigate([Route.Resources]);
  }
  onGotoServicesClick(): void
  {
    this.router.navigate([Route.Services]);
  }

  onProjectClick(projectId: number): void
  {
    this.router.navigate([Route.Project + "/" + projectId]);
  }
  onArticleClick(articleId: number): void
  {
    this.router.navigate([Route.Resource+ "/" + articleId]);
  }
}
