
import { OnDestroy, Pipe, PipeTransform } from '@angular/core';

import { I18nService } from '../services/i18n.service';
import { Subscription } from 'rxjs';

@Pipe({
    name: 'translate',
    standalone: true,
    pure: false
})

export class TranslatePipe implements PipeTransform, OnDestroy
{
    private markForTransform = true;
    private value: string = '';

    private sub: Subscription;

    constructor(private i18n: I18nService)
    {
        this.sub = this.i18n.onLanguageChange().subscribe(() => {
            this.markForTransform = true;
        });
    }

    transform(textIdName: string)
    {
        if (!this.markForTransform)
        {
            return this.value;
        }
        this.value = this.i18n.getText(textIdName);
        this.markForTransform = false;
        return this.value;
    }
    
    ngOnDestroy(): void
    {
        if (this.sub)
        {
            this.sub.unsubscribe();
        }
    }

}