import { Component, Input } from '@angular/core';
import { Article } from '../../interface/article';
import { TranslatePipe } from '../../pipe/translate.pipe';
import { MarkdownModule } from 'ngx-markdown';

@Component({
  selector: 'article-latest',
  standalone: true,
  imports: [TranslatePipe, MarkdownModule],
  templateUrl: './article-latest.component.html',
  styleUrl: './article-latest.component.scss'
})
export class ArticleLatestComponent 
{
  @Input() article!: Article;

  
}
