
<!-- Portfolio -->
<section class="justify-content-start position-relative">

  <!-- Background absolute de chevauchement -->
  <div class="bg-dark fullscreen"></div>

  <div class="d-flex flex-column mt-6">

    <h2 class="title">{{ 'portfolio.section_1.title' | translate }}</h2>

    <!-- Liste des activités -->
    <div class="d-flex flex-row justify-content-between mt-4">
      <img class="arrow-back me-3 cursor-pointer" [ngClass]="{ 'active' : canGoNext() }" src="../../../assets/images/pictos/arrow.svg" (click)="onPrevActivityClick()" />
      <div class="d-flex flex-row align-items-center overflow-hidden" #activityContainer>
        <div class="chip d-flex flex-column align-items-end cursor-pointer" [ngClass]="{ 'active' : act === activitySelected }" *ngFor="let act of activities" (click)="onActivityClick(act)">
          <div class="typo-chip ms-3">{{ act.attributes.name }}</div>
          <div class="blue-line"></div>
        </div>
      </div>
      <img class="arrow-next ms-3 cursor-pointer" [ngClass]="{ 'active' : canGoPrev() }" src="../../../assets/images/pictos/arrow.svg" (click)="onNextActivityClick()" />
    </div>
    
    <!-- Liste des projets filtrés -->
    <div class="row mt-4">
      <div class="col-12 col-sm-6 col-lg-4" *ngFor="let pjt of projects">
          <project [project]="pjt" (click)="onProjectClick(pjt.id)"></project>
      </div>

      <div class="col-12 text-center mt-3" *ngIf="projects.length === 0 && !loading">
        <div class="typo-chip fst-italic">{{ 'global.no_projects_filter' | translate }}</div>
      </div>
    </div>

  </div>

</section>

<!-- Depuis 15ans 
<section class="bg-dark position-relative">

  <div class="d-flex flex-column">
    <div class="subtitle text-light mt-6">
      {{ 'portfolio.section_2.title' | translate  }}
    </div>
    <div class="title-section color-primary mt-4">
      {{ 'portfolio.section_2.subtitle' | translate  }}
    </div>
    <div class="text-light mt-4">
      {{ 'portfolio.section_2.description' | translate  }}
    </div>
  </div>

  <div class="idea"></div>

</section>
-->

<!-- Cercle contact -->
<section class="bg-dark">
  <div class="d-flex flex-row justify-content-center align-items-center flex-grow-1">
      <circle-contact [useDarkTextCircle]="false">
        <div class="typo-circle-title ">{{ 'home.section_6.title' | translate }}</div>
        <div class="typo-circle-contactus mt-2">{{ 'home.section_6.contact_us' | translate }}</div>
      </circle-contact>
      
      <!-- Back to top-->
      <back-to-top [useBlue]="false"></back-to-top>
  </div>
</section>
