
<section class="bg-dark">

  <div class="d-flex flex-column">

    <h2 class="mt-6">{{ 'legalnotice.title' | translate }}</h2>

    <div class="typo-legalnotice mt-2" [innerHTML]="'legalnotice.content_1' | translate">{{ '' | translate }}</div>
    <div class="typo-legalnotice mt-2" [innerHTML]="'legalnotice.content_2' | translate">{{ '' | translate }}</div>
    <div class="typo-legalnotice mt-2" [innerHTML]="'legalnotice.content_3' | translate">{{ '' | translate }}</div>
    <div class="typo-legalnotice mt-2" [innerHTML]="'legalnotice.content_4' | translate">{{ '' | translate }}</div>
    <div class="typo-legalnotice mt-2" [innerHTML]="'legalnotice.content_5' | translate">{{ '' | translate }}</div>
    <div class="typo-legalnotice mt-2" [innerHTML]="'legalnotice.content_6' | translate">{{ '' | translate }}</div>
    <div class="typo-legalnotice mt-2" [innerHTML]="'legalnotice.content_7' | translate">{{ '' | translate }}</div>
    <div class="typo-legalnotice mt-2" [innerHTML]="'legalnotice.content_8' | translate">{{ '' | translate }}</div>

  </div>

</section>
