import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgcCookieConsentService, NgcInitializationErrorEvent, NgcInitializingEvent, NgcNoCookieLawEvent, NgcStatusChangeEvent } from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';
import { I18nService } from '../../services/i18n.service';

@Component({
  selector: 'cookieconsent',
  standalone: true,
  imports: [],
  templateUrl: './cookieconsent.component.html',
  styleUrl: './cookieconsent.component.scss'
})
export class CookieconsentComponent implements OnInit, OnDestroy
{
  private popupOpenSubscription!: Subscription;
  private popupCloseSubscription!: Subscription;
  private initializingSubscription!: Subscription;
  private initializedSubscription!: Subscription;
  private initializationErrorSubscription!: Subscription;
  private statusChangeSubscription!: Subscription;
  private revokeChoiceSubscription!: Subscription;
  private noCookieLawSubscription!: Subscription;
  
  private lgSub: Subscription;
  
  constructor(private cookieService: NgcCookieConsentService, i18nService: I18nService) 
  {
    this.lgSub = i18nService.onLanguageChange().subscribe(() => 
    {
      this.cookieService.getConfig().content = this.cookieService.getConfig().content || {} ;
      this.cookieService.getConfig().content!.header = i18nService.getText("cookie.header");
      this.cookieService.getConfig().content!.message = i18nService.getText("cookie.message");
      this.cookieService.getConfig().content!.dismiss = i18nService.getText("cookie.dismiss");
      this.cookieService.getConfig().content!.allow = i18nService.getText("cookie.allow");
      this.cookieService.getConfig().content!.deny = i18nService.getText("cookie.deny");
      this.cookieService.getConfig().content!.link = i18nService.getText("cookie.link");
      this.cookieService.getConfig().content!.policy = i18nService.getText("cookie.policy");
      
      this.cookieService.destroy(); // remove previous cookie bar (with default messages)
      this.cookieService.init(this.cookieService.getConfig());
    });
  }

  ngOnInit(): void
  {
    this.popupOpenSubscription = this.cookieService.popupOpen$.subscribe(() => {
      // you can use this.cookieService.getConfig() to do stuff...
    });

  this.popupCloseSubscription = this.cookieService.popupClose$.subscribe(() => {
      // you can use this.cookieService.getConfig() to do stuff...
    });

  this.initializingSubscription = this.cookieService.initializing$.subscribe((event: NgcInitializingEvent) => {
      // the cookieconsent is initilializing... Not yet safe to call methods like `NgcCookieConsentService.hasAnswered()`
      console.log(`initializing: ${JSON.stringify(event)}`);
    });
  
  this.initializedSubscription = this.cookieService.initialized$.subscribe(() => {
      // the cookieconsent has been successfully initialized.
      // It's now safe to use methods on NgcCookieConsentService that require it, like `hasAnswered()` for eg...
      console.log(`initialized: ${JSON.stringify(event)}`);
    });

  this.initializationErrorSubscription = this.cookieService.initializationError$.subscribe((event: NgcInitializationErrorEvent) => {
      // the cookieconsent has failed to initialize... 
      console.log(`initializationError: ${JSON.stringify(event.error?.message)}`);
    });

  this.statusChangeSubscription = this.cookieService.statusChange$.subscribe((event: NgcStatusChangeEvent) => {
      // you can use this.cookieService.getConfig() to do stuff...
    });

  this.revokeChoiceSubscription = this.cookieService.revokeChoice$.subscribe(() => {
      // you can use this.cookieService.getConfig() to do stuff...
    });

    this.noCookieLawSubscription = this.cookieService.noCookieLaw$.subscribe((event: NgcNoCookieLawEvent) => {
      // you can use this.cookieService.getConfig() to do stuff...
    });
  }

  ngOnDestroy(): void 
  {
    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializingSubscription.unsubscribe();
    this.initializedSubscription.unsubscribe();
    this.initializationErrorSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();
    
    this.lgSub.unsubscribe();
  }
}
