import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ArticleComponent } from '../../component/article/article.component';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { TranslatePipe } from '../../pipe/translate.pipe';
import { Route } from '../../interface/route';
import { Apollo } from 'apollo-angular';
import { I18nService } from '../../services/i18n.service';
import { Router } from '@angular/router';
import { ThematicsService } from '../../services/thematics.service';
import { Thematic } from '../../interface/thematic';
import { Activity } from '../../interface/activity';
import { ArticlesService } from '../../services/articles.service';
import { Article } from '../../interface/article';
import { Subscription } from 'rxjs';
import { ArticleLatestComponent } from '../../component/article-latest/article-latest.component';
import { CircleContactComponent } from '../../component/circle-contact/circle-contact.component';
import { BackToTopComponent } from '../../component/back-to-top/back-to-top.component';
import { SeoService } from '../../services/seo.service';

@Component({
  selector: 'app-articles',
  standalone: true,
  imports: [NgFor, NgClass, NgIf, TranslatePipe, ArticleComponent, ArticleLatestComponent, CircleContactComponent, BackToTopComponent],
  templateUrl: './articles.component.html',
  styleUrl: './articles.component.scss'
})
export class ArticlesComponent implements OnInit, OnDestroy
{
  articles: Article[] = [];
  thematics: Thematic[] = [];

  loading : boolean = true;

  thematicSelected: Thematic | undefined;

  private lgSub: Subscription;
  
  @ViewChild('thematicContainer', { static: false }) thematicContainer!: ElementRef;
  
  constructor(  private apollo: Apollo, private articlesService: ArticlesService, private thematicsService: ThematicsService, 
                private i18Service: I18nService, private router: Router, private i18nService: I18nService, private seoService: SeoService)
  {
    this.lgSub = this.i18nService.onLanguageChange().subscribe(() => {
      this.seoService.setPage("articles");

      this.reloadThematics();
      this.reloadArticles();
    });
  }

  ngOnInit(): void
  {
    this.reloadThematics();
    this.reloadArticles();
  }

  ngOnDestroy(): void 
  {
    this.lgSub.unsubscribe();
  }

  reloadThematics(): void
  {
    const thematicAll: Thematic = { id: 0, attributes: { name: this.i18Service.getText("global.all") } };
    this.thematicSelected = thematicAll;

    this.apollo.query({ query: this.thematicsService.getThematicsQuery() }).subscribe(({data, error}: any) =>
    {

      this.thematics = [];
      this.thematics.push(thematicAll);
      this.thematics = this.thematics.concat(data.thematics.data);
    });
  }

  reloadArticles()
  {
    this.loading = true;

    let variablesArticles = { thematic: "", sort: "order:DESC", limit: 100 };
    if (this.thematicSelected && this.thematicSelected.attributes.name !== this.i18Service.getText("global.all"))
    {
      variablesArticles.thematic = this.thematicSelected.attributes.name;
    }

    this.apollo.query({ query: this.articlesService.getArticles(), variables: variablesArticles }).subscribe(({data, error}: any) => 
    {
      this.articles = [];

      this.loading = false;
      this.articles = data.articles.data;
    });
  }

  onArticleClick(articleId: number): void
  {
    this.router.navigate([Route.Resource + "/" + articleId]);
  }

  onActivityClick(activity: Activity): void
  {
    this.thematicSelected = activity;

    this.reloadArticles();
    this.scrollToSelected();
  }

  onPrevActivityClick(): void
  {
    let index = this.thematics.findIndex(act => act.id === this.thematicSelected?.id);
    if (index !== -1)
    {
      if (index - 1 >= 0)
      {
        this.thematicSelected = this.thematics[index - 1];
        this.reloadArticles();
        this.scrollToSelected();
      }
    }
  }

  onNextActivityClick(): void
  {
    let index = this.thematics.findIndex(act => act.id === this.thematicSelected?.id);
    if (index !== -1)
    {
      if (index + 1 < this.thematics.length)
      {
        this.thematicSelected = this.thematics[index + 1];
        this.reloadArticles();
        this.scrollToSelected();
      }
    }
  }

  scrollToSelected(): void
  {
    // Le setTimeout avec un délai de 0ms permet d'attendre la prochaine boucle d'événements du navigateur, garantissant que les modifications du DOM sont appliquées
    setTimeout(() => {
      if (this.thematicSelected)
        {
          const container = this.thematicContainer.nativeElement;
          const selectedElement = container.querySelector('.active');
          if (selectedElement) {
            const containerRect = container.getBoundingClientRect();
            const selectedRect = selectedElement.getBoundingClientRect();
            const offset = selectedRect.left - containerRect.left;
            container.scrollTo({
              left: container.scrollLeft + offset - (container.clientWidth / 2 - selectedRect.width / 2),
              behavior: 'smooth'
            });
          }
        }
    }, 0);
  }

  canGoNext(): boolean
  {
    if (this.thematicSelected && this.thematics.length > 0 && this.thematicSelected !== this.thematics[0]) return true;
    return false;
  }

  canGoPrev(): boolean
  {
    if (this.thematicSelected && this.thematics.length > 0 && this.thematicSelected !== this.thematics[this.thematics.length-1]) return true;
    return false;
  }
}
