
import { Injectable } from '@angular/core';
import gql from "graphql-tag";
import { I18nService } from './i18n.service';

@Injectable({
  providedIn: 'root'
})
export class LatestService 
{
  constructor(private i18nService: I18nService) 
  {
  }

  public getLatest() {
    const currentLanguage = this.i18nService.Language;
    return gql`
    query Latest 
      {
        latest(locale: "${currentLanguage}")
        {
          data 
          {
            id,
            attributes 
            {
              projects
              {
                data
                {
                  id
                  attributes
                  {
                    name
                    company
                    description
                    categories{
                      data {
                        id, 
                        attributes{name}
                      }
                    }
                    preview {
                      data {
                        id,
                        attributes {
                          url
                        }
                      }
                    },
                  }
                }
              }
              
              articles
              {
                data
                {
                  id
                  attributes
                  {
                    name
                    description
                    content
                  }
                }
              }
            }
          }	
        }
      }
    `;
  }
}
