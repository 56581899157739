import { AfterViewInit, Component, HostListener, Input, OnDestroy } from '@angular/core';
import { TranslatePipe } from '../../pipe/translate.pipe';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

@Component({
  selector: 'methodology',
  standalone: true,
  imports: [TranslatePipe],
  templateUrl: './methodology.component.html',
  styleUrl: './methodology.component.scss'
})
export class MethodologyComponent implements AfterViewInit, OnDestroy
{
  @Input() trigger!: string;
  
  private anim: gsap.core.Tween | undefined;

  constructor()
  {
    gsap.registerPlugin(ScrollTrigger);
  }
  
  ngAfterViewInit(): void 
  {
    // Attend l'affichage des catégories ainsi que le scroll top automatique au changement d'url d'angular
    setTimeout(() => {
      this.calculateGsapAnim();
    }, 1000);
  }
  ngOnDestroy(): void
  {
    this.cleanAnimations();
  }

  calculateGsapAnim()
  {
    let container = document.querySelector(".methodo-list");
    let methodologies = document.querySelectorAll(".methodo-translate");

    let containerWidth = container!.getBoundingClientRect().width;
    let methodologiesWidth = methodologies[0].getBoundingClientRect().width * methodologies.length;
    let methodologiesGapWidth = 64 * (methodologies.length - 1);  // gap : 4rem = 64px
    let displacementX = methodologiesWidth + methodologiesGapWidth - containerWidth;

    this.anim = gsap.to(methodologies, {
      scrollTrigger: 
      {
        invalidateOnRefresh: true,
        trigger: this.trigger,
        start: "top top",
        pin: true,
        scrub: 0.5,
        //end: "top top",
        //onRefresh: self => window.scrollTo(0, 0)
      },
      x: -displacementX,
    });
    
    //ScrollTrigger.refresh(true);
  }
  
  cleanAnimations(): void
  {
    if (this.anim) this.anim.kill();
    
    ScrollTrigger.getAll().forEach(trigger => trigger.kill());
    ScrollTrigger.clearScrollMemory();
  }
}
