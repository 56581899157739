

import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { I18nService } from './i18n.service';

@Injectable({
    providedIn: 'root'
})
export class SeoService 
{
    constructor(private titleService: Title, private metaService: Meta, private i18nService: I18nService) 
    {
      this.setPage("home");
    }

    public setPage(idI18nPage: string): void
    {
      const title = this.i18nService.getText("seo." + idI18nPage + ".title");
      const description = this.i18nService.getText("seo." + idI18nPage + ".description");
      const keywords = this.i18nService.getText("seo." + idI18nPage + ".keywords");

      this.setMeta(title, description, keywords);
    }

    public setMeta(title: string, description: string, keywords: string): void
    {
      this.titleService.setTitle(title);

      this.metaService.updateTag({ name: "description", content: description });
      this.metaService.updateTag({ name: "keywords", content: keywords });

      this.metaService.updateTag({ property: "og:title", content: title });
      this.metaService.updateTag({ property: "og:description", content: description });
      this.metaService.updateTag({ property: "og:type", content: "website" });
      this.metaService.updateTag({ property: "og:url", content: window.location.href });
      //this.metaService.updateTag({ property: "og:image", content: "https://www.example.com/image.jpg" }); // Remplacez par l'URL de votre image

      this.metaService.updateTag({ name: "twitter:card", content: "summary_large_image" });
      this.metaService.updateTag({ name: "twitter:title", content: title });
      this.metaService.updateTag({ name: "twitter:description", content: description });
      //this.metaService.updateTag({ name: "twitter:image", content: "https://www.example.com/image.jpg" }); // Remplacez par l'URL de votre image

      this.metaService.updateTag({ name: "robots", content: "index, follow" });
      this.metaService.updateTag({ rel: "canonical", href: window.location.href });
    }
}
