import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SeoService } from '../../services/seo.service';
import { I18nService } from '../../services/i18n.service';
import AOS from "aos";
import { BubbleBackgroundComponent } from '../../component/bubble-background/bubble-background.component';
import { BackToTopComponent } from '../../component/back-to-top/back-to-top.component';
import { CircleContactComponent } from '../../component/circle-contact/circle-contact.component';
import { TranslatePipe } from '../../pipe/translate.pipe';

@Component({
  selector: 'app-ourplatforms',
  standalone: true,
  imports: [TranslatePipe, BubbleBackgroundComponent, BackToTopComponent, CircleContactComponent],
  templateUrl: './ourplatforms.component.html',
  styleUrl: './ourplatforms.component.scss'
})
export class OurplatformsComponent 
{
  private lgSub: Subscription;
  
  constructor(private router: Router, private seoService: SeoService, private i18nService: I18nService)
  {
    this.lgSub = this.i18nService.onLanguageChange().subscribe(() => {
      this.seoService.setPage("ourplatforms");
    });
  }

  ngOnInit(): void
  {
    AOS.init();
  }

  ngOnDestroy(): void
  {
    this.lgSub.unsubscribe();
  }
}
