import { Component, OnDestroy } from '@angular/core';
import { TranslatePipe } from '../../pipe/translate.pipe';
import { RecaptchaV3Module, ReCaptchaV3Service } from 'ng-recaptcha';
import { ContactService } from '../../services/contact.service';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { NgIf } from '@angular/common';
import { SeoService } from '../../services/seo.service';
import { I18nService } from '../../services/i18n.service';
import { Subscription } from 'rxjs';

export class NewsletterInputData
{
    data: NewsletterInput = new NewsletterInput();
    token: string = '';
}

export class NewsletterInput
{
    lastName: string = '';
    firstName: string = '';
    company: string = '';
    mail: string = '';
    source: string = '';
}

@Component({
  selector: 'app-newsletter',
  standalone: true,
  imports: [TranslatePipe, RecaptchaV3Module, ReactiveFormsModule, NgIf],
  templateUrl: './newsletter.component.html',
  styleUrl: './newsletter.component.scss'
})
export class NewsletterComponent implements OnDestroy
{
  capchaValid: boolean = false;
  capchaToken: string = '';
  
  get f(): { [key: string]: AbstractControl } { return this.form.controls; }
  
  form: FormGroup;

  submitted = false;
  leadMessageBackend: string = 'ERROR';
  leadSend: boolean = false;

  private lgSub: Subscription;
  
  constructor(private http: HttpClient, private recaptchaV3Service: ReCaptchaV3Service, private formBuilder: FormBuilder, private seoService: SeoService, private i18nService: I18nService)
  {
    this.lgSub = this.i18nService.onLanguageChange().subscribe(() => {
      this.seoService.setPage("newsletter");
    });

    this.form = this.formBuilder.group(
    {
      name: ['', [Validators.required]],
      surname: [''],
      company: ['', [Validators.required]],
      mail: ['', [Validators.required, Validators.email]],
    });
  }

  ngOnDestroy(): void
  {
    this.lgSub.unsubscribe();
  }
  
  public executeImportantAction(): void 
  {
    this.recaptchaV3Service.execute('importantAction').subscribe((token) => 
    {
      this.capchaValid = true;
      this.capchaToken = token;
    });
  }

  public onNewsletterSubmit(): void
  {
    this.submitted = true;

    if (!this.form.invalid && this.capchaValid)
    {
      this.submitted = false;
      
      var body: NewsletterInputData = { 
          data: {
            lastName: this.form.get("name")?.value, 
            firstName: this.form.get("surname")?.value, 
            mail: this.form.get("mail")?.value, 
            company: this.form.get("company")?.value,
            source: "FROM_Newsletter"
        },
        token: this.capchaToken
      };

      this.http.post<NewsletterInputData>(environment.backendUrl + "/api/leads", body).subscribe((response: any) => 
      {
        this.leadSend = true;
        if (response.data.attributes.data.length > 0)
        {
          if (response.data.attributes.data[0].code == "SUCCESS")
          {
            this.leadMessageBackend = 'global.newsletter_sent';
          }
          else if  (response.data.attributes.data[0].code == "DUPLICATE_DATA")
          {
            this.leadMessageBackend = 'global.newsletter_duplicate';
          }
          else
          {
            this.leadMessageBackend = 'global.newsletter_error';
          }
        }
      });
    }
  }
}
