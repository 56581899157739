
import { Injectable } from '@angular/core';
import gql from "graphql-tag";
import { I18nService } from './i18n.service';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService 
{
  constructor(private i18nService: I18nService) {}

  public getCategorieQuery() {
    const currentLanguage = this.i18nService.Language;

    return gql`
    query Categories {
      categories(
        locale: "${currentLanguage}",
      ) {
        data {
          id,
          attributes {
            name,
            icon {
              data {
                attributes {
                  url
                }
              }
            },
            description,
            content_title,
            content,
            projects {
              data {
                id,
                attributes {
                  name,
                  isLarge,
                  company,
                  description,
                  preview {
                    data {
                      attributes {
                        url
                      }
                    }
                  }
                }
              }
            }
            footer_title,
            footer
          }
        }
      }
    }
    `;
  }
}
