
<img class="logo" src='../../../assets/logo/itycom-by-digitalis.png' [ngClass]="{ 'open': isMenuOpen }" alt="logo" (click)="onLogoClick()" />

<div class="menu-button" (click)="onToggleMenu()">

    <span class="bar" [ngClass]="{ 'open': isMenuOpen}"></span>
    <span class="bar small" [ngClass]="{ 'open': isMenuOpen}"></span>
    <span class="bar" [ngClass]="{ 'open': isMenuOpen}"></span>

</div>

<div class="menu-lang d-flex flex-row align-items-center">
    <div class="typo-lang cursor-pointer" [ngClass]="{ 'active': i18nService.Language === 'fr' }" (click)="onToggleLang()">FR</div>
    <div class="mx-1 typo-lang">/</div>
    <div class="typo-lang cursor-pointer" [ngClass]="{ 'active': i18nService.Language === 'en' }" (click)="onToggleLang()">EN</div>
</div>

<!-- Menu ouvert -->
<div class="header bg-header container-ity" [ngClass]="{ 'open': isMenuOpen }">
    <div class="d-flex flex-column justify-content-center mt-6">
        <div class="d-flex flex-row align-items-center align-items-lg-start menu-anim cursor-pointer" (click)="onHomeClick()">
            <div class="typo-menu-title">{{ 'menu.home.title' | translate }}</div>
            <div class="typo-menu-number ms-3">01</div>
        </div>
        <div class="d-flex flex-row align-items-center align-items-lg-start menu-anim cursor-pointer" (click)="onOurplatformsClick()">
            <div class="typo-menu-number">02</div>
            <div class="typo-menu-title ms-3">{{ 'menu.ourplatforms.title' | translate }}</div>
        </div>
        <div class="d-flex flex-row align-items-center align-items-lg-start menu-anim cursor-pointer" (click)="onServicesClick()">
            <div class="typo-menu-title">{{ 'menu.services.title' | translate }}</div>
            <div class="typo-menu-number ms-3">03</div>
        </div>
        <div class="d-flex flex-row align-items-center align-items-lg-start menu-anim cursor-pointer" (click)="onPortfolioClick()">
            <div class="typo-menu-number">04</div>
            <div class="typo-menu-title ms-3">{{ 'menu.portfolio.title' | translate }}</div>
        </div>
        <div class="d-flex flex-row align-items-center align-items-lg-start menu-anim cursor-pointer" (click)="onAgencyClick()">
            <div class="typo-menu-title">{{ 'menu.agency.title' | translate }}</div>
            <div class="typo-menu-number ms-3">05</div>
        </div>
        <div class="d-flex flex-row align-items-center align-items-lg-start menu-anim cursor-pointer" (click)="onArticlesClick()">
            <div class="typo-menu-number">06</div>
            <div class="typo-menu-title ms-3">{{ 'menu.articles.title' | translate }}</div>
        </div>
        <div class="d-flex flex-row align-items-center align-items-lg-start menu-anim cursor-pointer" (click)="onContactClick()">
            <div class="typo-menu-title">{{ 'menu.contact.title' | translate }}</div>
            <div class="typo-menu-number ms-3">07</div>
        </div>
    </div>
</div>