import { AfterViewInit, Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { gsap } from 'gsap';

@Component({
  selector: 'bubble-background',
  standalone: true,
  imports: [],
  templateUrl: './bubble-background.component.html',
  styleUrl: './bubble-background.component.scss'
})
export class BubbleBackgroundComponent implements AfterViewInit
{
  @ViewChild('bubbleContainer', { static: true }) bubbleContainer!: ElementRef;
  
  bubbleType =   { ease:"large", duration: 4, min: 0.4, max: 1, scaleEase:"power4.in" }
  
  master: gsap.core.Timeline;

  constructor(private renderer: Renderer2)
  {
    this.master = gsap.timeline();
  }

  ngAfterViewInit(): void 
  {
    this.createBubbles(30);
  }
  
  createBubbles(amount: number): void
  {
    const width = this.bubbleContainer.nativeElement.clientWidth;
    const height = this.bubbleContainer.nativeElement.clientHeight;
    const halfWidth = width / 2;
    
    for(let i = 0; i < amount; i++)
    {
      let bubble = this.renderer.createElement("div");
      this.renderer.addClass(bubble, 'bubble');
      this.renderer.appendChild(this.bubbleContainer.nativeElement, bubble);
      
      let scale = gsap.utils.random(this.bubbleType.min, this.bubbleType.max)
      let ease = this.bubbleType.ease;
      let scaleEase = this.bubbleType.scaleEase;
      let duration = this.bubbleType.duration;
      let tl = gsap.timeline({repeat:-1, repeatDelay:Math.random() * 3})
      let relativeDirection = Math.random() < 0.5 ? "+=" : "-=";
      
      tl.set(bubble, { x: halfWidth + gsap.utils.random(0, halfWidth), y: height + (100 * scale), xPercent:-50, yPercent:-50, scale:0 })
      
      tl.to(bubble, { y:-200, duration:duration, ease:"power1.in"})
      tl.to(bubble, { duration:1, scale:scale, ease:scaleEase}, gsap.utils.random(0, 0.5))
      tl.to(bubble, { x: halfWidth + relativeDirection + scale * 80, ease:ease, duration:duration * 2 }, gsap.utils.random(0.3, 1.5))
      
      this.master.add(tl, i * 0.1)
    }

    this.master.timeScale(600 / height)
    this.master.time(100000)
  }
}
