
<!-- Header -->
<section class="bg-dark justify-content-center position-relative">

  <bubble-background></bubble-background>

  <div class="typo-nav-title">{{ 'menu.ourplatforms.title_nav' | translate }}</div>
  <h2 class="mt-2">{{ 'ourplatforms.title' | translate }}</h2>

  <div class="row mt-3">
    <div class="col-12 col-lg-8">
      <div class="text-light">{{ 'ourplatforms.subtitle' | translate }}</div>
    </div>
    <div class="offset-2 col-8 offset-lg-1 col-lg-3">
      <div class="d-flex align-items-center h-100">
        <img class="mt-5 mt-lg-0 img-fluid" src="../../../assets/images/ourplatforms/digitalis-logo-blanc.png" />
      </div>
    </div>
  </div>

</section> 

<!-- Conseils -->
<section class="bg-light" id="section1">

  <div data-aos="fade-down" class="typo-rp-bold color-secondary">{{ 'ourplatforms.section_1.title' | translate }}</div>
  <h2 data-aos="fade-right" class="color-secondary mt-2">{{ 'ourplatforms.section_1.subtitle' | translate }}<span class="line secondary"></span></h2>

  <div class="row mt-3">
    <div class="col-12 col-lg-8">
      <div data-aos="zoom-in" class="mt-1" [innerHTML]="'ourplatforms.section_1.description' | translate"></div>
    </div>
    <div class="offset-3 col-6 offset-lg-1 col-lg-3 offset-xxl-2 col-xxl-2">
      <div class="d-flex align-items-center h-100">
        <img data-aos="zoom-in" class="mt-5 mt-lg-0 img-fluid" src="../../../assets/images/ourplatforms/Totara-Alliance.png" />
      </div>
    </div>
  </div>

</section>

<!-- Création sur mesure -->
<section class="section bg-dark" id="section2">
  
  <div data-aos="fade-down" class="text-light typo-rp-bold">{{ 'ourplatforms.section_2.title' | translate }}</div>
  <h2 data-aos="fade-right" class="mt-2">{{ 'ourplatforms.section_2.subtitle' | translate }}<span class="line"></span></h2>

  <div class="row mt-3">
    <div class="col-12 col-lg-8">
      <div data-aos="zoom-in" class="digital text-light mt-1"  [innerHTML]="'ourplatforms.section_2.description' | translate"></div>
    </div>
    <div class="offset-2 col-8 offset-lg-1 col-lg-3">
      <div class="d-flex align-items-center h-100">
        <img data-aos="zoom-in" class="mt-5 mt-lg-0 img-fluid" src="../../../assets/images/ourplatforms/cm-logo.png" />
      </div>
    </div>
  </div>

</section>

<!-- Cercle contact -->
<section class="bg-dark">
  <div class="d-flex flex-row justify-content-center align-items-center flex-grow-1">
      <circle-contact [useDarkTextCircle]="false">
        <div class="typo-circle-title ">{{ 'home.section_6.title' | translate }}</div>
        <div class="typo-circle-contactus mt-2">{{ 'home.section_6.contact_us' | translate }}</div>
      </circle-contact>
  </div>

  <!-- Back to top-->
  <back-to-top [useBlue]="false"></back-to-top>
</section>
