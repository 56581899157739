import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslatePipe } from '../../pipe/translate.pipe';
import { Router } from '@angular/router';
import { Route } from '../../interface/route';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { CircleContactComponent } from '../../component/circle-contact/circle-contact.component';
import { FormationComponent } from '../../component/formation/formation.component';

import AOS from "aos";
import { SolutionComponent } from '../../component/solution/solution.component';
import { BubbleBackgroundComponent } from '../../component/bubble-background/bubble-background.component';
import { BackToTopComponent } from '../../component/back-to-top/back-to-top.component';
import { SeoService } from '../../services/seo.service';
import { I18nService } from '../../services/i18n.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-services',
  standalone: true,
  imports: [NgFor, NgClass, NgIf, TranslatePipe, CircleContactComponent, FormationComponent, SolutionComponent, BubbleBackgroundComponent, BackToTopComponent],
  templateUrl: './services.component.html',
  styleUrl: './services.component.scss'
})
export class ServicesComponent implements OnInit, OnDestroy
{
  private lgSub: Subscription;
  
  constructor(private router: Router, private seoService: SeoService, public i18nService: I18nService)
  {
    this.lgSub = this.i18nService.onLanguageChange().subscribe(() => {
      this.seoService.setPage("services");
    });
  }

  ngOnInit(): void
  {
    AOS.init();
  }

  ngOnDestroy(): void
  {
    this.lgSub.unsubscribe();
  }
  
  onGotoPortfolioClick(): void
  {
    this.router.navigate([Route.Porfolio]);
  }
}
