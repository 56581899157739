import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { HeaderComponent } from '../../component/header/header.component';
import { FooterComponent } from '../../component/footer/footer.component';
import { CookieconsentComponent } from '../../component/cookieconsent/cookieconsent.component';
import { loadEmbed, loadSpace } from '@usersnap/browser';
import { environment } from '../../../environments/environment';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { filter } from 'rxjs';

declare var gtag: Function;

@Component({
  selector: 'main',
  standalone: true,
  imports: [HeaderComponent, FooterComponent, RouterOutlet, CookieconsentComponent],
  templateUrl: './main.component.html',
  styleUrl: './main.component.scss'
})
export class MainComponent implements AfterViewInit {
  @ViewChild('mainContainer', { static: true }) mainContainer!: HTMLElement;

  constructor(private gtmService: GoogleTagManagerService, private router: Router) {
  }

  ngAfterViewInit(): void {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: any) => {
      if (typeof gtag === 'function') {
        //console.log("Google Analytics - Tracking page:", event.urlAfterRedirects);
        gtag('config', environment.googleAnalyticsId, { 'page_path': event.urlAfterRedirects });

        const gtmTag = {
          event: 'page',
          pageName: event.urlAfterRedirects
        };
        //console.log("Google Tag Manager - Pushing tag:", gtmTag);
        this.gtmService.pushTag(gtmTag);
      } else {
        console.error("gtag function is not defined.");
      }
    });
    // Tracking des retours via UserSnap
    const targetNode = document.getElementById('usersnapContainer');
    if (targetNode && !environment.production) {
      loadEmbed("592ec067-aee1-4055-beca-7baad74152ee").then((api) => {
        api.init({
          mountNode: targetNode,
        })
      }).catch(err => {
        console.log("Usersnap error: " + err);
      })
    }
  }
}
