
<!-- Header -->
<section class="bg-dark">

  <bubble-background></bubble-background>

  <div class="d-flex flex-row align-items-center cursor-pointer mt-6" (click)="onBackClick()">  
    <img class="arrow-back active me-3" src="../../../assets/images/pictos/arrow.svg"  />
    <div class="typo-project-backbtn text-light">{{ 'global.home' | translate }}</div>
  </div>

  <h2 class="mt-6">404</h2>
  <div class="text-light w-adj mt-2">{{ 'global.pagenotfound' | translate }}</div>

</section>