import { Component, OnDestroy } from '@angular/core';
import { Project } from '../../interface/project';
import { ActivatedRoute, Router } from '@angular/router';
import { Route } from '../../interface/route';
import { TranslatePipe } from '../../pipe/translate.pipe';
import { Apollo } from 'apollo-angular';
import { ProjectsService } from '../../services/projects.service';
import { environment } from '../../../environments/environment';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { I18nService } from '../../services/i18n.service';
import { Subscription } from 'rxjs';
import { MarkdownModule } from 'ngx-markdown';
import { SeoService } from '../../services/seo.service';

@Component({
  selector: 'app-single-project',
  standalone: true,
  imports: [NgFor, NgClass, NgIf, TranslatePipe, MarkdownModule],
  templateUrl: './single-project.component.html',
  styleUrl: './single-project.component.scss'
})
export class SingleProjectComponent implements OnDestroy
{
  project!: Project;
  
  backendUrl: string = environment.backendUrl;

  private lgSub: Subscription;
  
  constructor(private apollo: Apollo, private projectsService: ProjectsService, private activatedRoute: ActivatedRoute, private router: Router, private i18nService: I18nService, private seoService: SeoService)
  {
    let routeId = this.activatedRoute.snapshot.params['id'];
    let variablesProjects = { id: routeId };
    
    this.lgSub = this.i18nService.onLanguageChange().subscribe(() =>
    {
      this.apollo.query({ query: this.projectsService.getProject(), variables: variablesProjects }).subscribe(({data, error}: any) => 
      {
        this.project = data.project.data;
        
        if (this.project.attributes.SEO)
        {
          this.seoService.setMeta(this.project.attributes.SEO.title, this.project.attributes.SEO.description, this.project.attributes.SEO.keywords);
        }
      });
    });
  }

  ngOnDestroy(): void
  {
    this.lgSub.unsubscribe();
  }
  
  onBackClick(): void
  {
    this.router.navigate([Route.Porfolio]);
  }
}
