
<!-- Header -->
<section class="bg-dark justify-content-center position-relative">

  <bubble-background></bubble-background>

  <div class="typo-nav-title mt-4">{{ 'menu.agency.title_nav' | translate }}</div>
  <h2 class="mt-2">{{ 'agency.title' | translate }}</h2>
  <div class="text-light w-adj mt-3">{{ 'agency.subtitle' | translate }}</div>

</section>

<!-- 16ans d'expertise -->
<section class="bg-light position-relative">

  <div class="d-flex flex-column">
      <div data-aos="fade-down" class="d-flex flex-row align-items-center">
          <img src="../../../assets/images/pictos/Picto_star.svg" />
          <div class="color-primary subtitle typo-rp-bold ms-3">{{ 'home.section_2.expert' | translate  }}</div>
      </div>

      <h2 data-aos="fade-right" class="title-section color-secondary mt-2">{{ 'home.section_2.title' | translate  }}<span class="line secondary"></span></h2>

      <div class="d-flex flex-row justify-content-around align-items-center text-center flex-wrap mt-5 gap-2">
          <circle-stat data="350">{{ 'home.section_2.keys.customers' | translate }}</circle-stat>
          <circle-stat data="99%">{{ 'home.section_2.keys.satisfaction' | translate }}</circle-stat>
          <circle-stat data="2000">{{ 'home.section_2.keys.projects' | translate }}</circle-stat>
          <circle-stat data="30">{{ 'home.section_2.keys.collaborater' | translate }}</circle-stat>
      </div>
  </div>
</section>

<!-- Groupe -->
<section class="bg-dark position-relative" id="section2">
  
  <bubble-background></bubble-background>
  
  <div class="d-flex flex-column">
    <div data-aos="fade-down" class="typo-nav-title">{{ 'agency.vocation' | translate }}</div>
    <h2 data-aos="fade-right" class="title mt-2">{{ 'agency.vocation-title' | translate }}<span class="line"></span></h2>
    <div data-aos="zoom-in" class="text-light w-adj mt-1">{{ 'agency.vocation-subtitle' | translate }}</div>
  </div>

    <!-- White arrow -->
    <div class="section-link d-flex flex-row justify-content-center align-items-center cursor-pointer" (click)="onGotoPortfolioClick()">
      <div class="typo-section-link text-light me-2">{{ 'home.next.portfolio' | translate }}</div>
      <img class="arrow" src="../../../assets/images/arrow-white.svg" />
  </div>

</section>

<!-- Antennas -->
<section class="justify-content-center bg-light" id="section1">

  <div class="d-flex flex-column">
    <div data-aos="fade-down" class="d-flex flex-row align-items-center">
        <img src="../../../assets/images/pictos/location.svg" />
        <div class="color-secondary typo-rp-bold ms-3">{{ 'agency.antennas-title' | translate }}</div>
    </div>

    <div data-aos="fade-right" class="d-flex justify-content-center justify-content-lg-start typo-location color-secondary mt-6 text-center">{{ 'global.location.geneva' | translate }} · {{ 'global.location.lyon' | translate }}</div>
    <div data-aos="fade-left" class="d-flex justify-content-center justify-content-lg-end typo-location color-secondary mt-5 text-center">{{ 'global.location.paris' | translate }} · {{ 'global.location.zurich' | translate }}</div>
  </div>

</section>

<!-- Video teaser -->
<section class="bg-dark position-relative section-small">

  <video [autoplay]="true" [muted]="true" [loop]="true" [playsInline]="true" class="video-teaser">
      <source [src]="teaserLink" type="video/mp4" />
  </video>

</section>

<!-- Methodology -->
<section class="bg-light" id="section3">
  
  <div class="d-flex flex-column">
    <div data-aos="fade-down" class="typo-rp-bold color-secondary">{{ 'agency.methodology' | translate }}</div>
    <h2 data-aos="fade-right" class="color-secondary mt-2">{{ 'agency.methodology-title' | translate }}<span class="line secondary"></span></h2>
    <div data-aos="zoom-in" class="mt-1">{{ 'agency.methodology-subtitle' | translate }}</div>
    
    <methodology class="mt-4" trigger="#section3"></methodology>
  </div>
  
</section>

<!-- Cercle contact -->
<section class="bg-dark">
  <div class="d-flex flex-row justify-content-center align-items-center flex-grow-1">
      <circle-contact [useDarkTextCircle]="false">
        <div class="typo-circle-title ">{{ 'home.section_6.title' | translate }}</div>
        <div class="typo-circle-contactus mt-2">{{ 'home.section_6.contact_us' | translate }}</div>
      </circle-contact>
      
      <!-- Back to top-->
      <back-to-top [useBlue]="false"></back-to-top>
  </div>
</section>
