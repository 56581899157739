import { AfterViewInit, Component, HostListener, Input, OnDestroy} from '@angular/core';
import { Apollo } from 'apollo-angular';
import { CategoriesService } from '../../services/categories.service';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { NgFor } from '@angular/common';
import { Category } from '../../interface/category';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'solution',
  standalone: true,
  imports: [NgFor],
  templateUrl: './solution.component.html',
  styleUrl: './solution.component.scss'
})
export class SolutionComponent implements AfterViewInit, OnDestroy
{
  @Input() trigger!: string;

  categories: Category[] = [];
  
  backendUrl: string = environment.backendUrl;

  private anim: gsap.core.Tween | undefined;

  constructor(private apollo: Apollo, private categoriesService: CategoriesService)
  {
    gsap.registerPlugin(ScrollTrigger);
  }

  ngAfterViewInit(): void
  {
    this.loadCategories();
  }
  ngOnDestroy(): void
  {
    this.cleanAnimations();
  }

  loadCategories(): void
  {
    this.apollo.query({ query: this.categoriesService.getCategorieQuery() }).subscribe(({data, error}: any) =>
    {
      this.categories = data.categories.data;
      
      if (this.categories.length > 0)
      {
        // Attend l'affichage des catégories ainsi que le scroll top automatique au changement d'url d'angular
        setTimeout(() => {
          this.calculateGsapAnim();
        }, 1000);
      }
    });
  }

  calculateGsapAnim()
  {
    this.cleanAnimations();
    
    let container = document.querySelector(".categories-container");
    let categories = document.querySelectorAll(".categories-translate");

    let containerWidth = container!.getBoundingClientRect().width;
    let categoriesWidth = categories[0].getBoundingClientRect().width * categories.length;
    let categoriesGapWidth = 64 * (categories.length - 1);  // gap : 4rem = 64px
    let displacementX = categoriesWidth + categoriesGapWidth - containerWidth;

    this.anim = gsap.to(categories, {
      scrollTrigger: 
      {
        invalidateOnRefresh: true,
        trigger: this.trigger,
        pin: true,
        scrub: 0.5,
        start: "top top"
        //end: "+=" + displacementX,
        //onRefresh: self => window.scrollTo(0, 0)
      },
      x: -displacementX,
    });
  }

  cleanAnimations(): void
  {
    if (this.anim) this.anim.kill();

    ScrollTrigger.getAll().forEach(trigger => trigger.kill());
    ScrollTrigger.clearScrollMemory();
  }
}
