
<div class="sec">
  
  <div class="circle">
    <div class="circle-text number-stat">
      {{ data }}
    </div>
  </div>
  <div class="color-secondary d-flex justify-content-center text-center text-uppercase typo-circle-stat mt-2 mb-3">
    <ng-content></ng-content> 
  </div>

</div>
