
<div class="categories-container d-flex flex-row flex-nowrap g-4 mt-4 py-2">

  <div class="categories-translate">
    <ng-container *ngFor="let cat of categories">

      <div class="category gsap-elem">
        <div class="category-content d-flex justify-content-center align-items-center">
    
          <div class="d-flex flex-column justify-content-around align-items-center text-center">
            <img class="icon" [src]="backendUrl + cat.attributes.icon.data.attributes.url" />
            <div class="mt-3 typo-category-title">{{ cat.attributes.name }}</div>
          </div>
    
        </div>
      </div>
      
    </ng-container>
  </div>

</div>