import { AfterViewInit, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Route } from '../../interface/route';
import { NgClass } from '@angular/common';

@Component({
  selector: 'circle-contact',
  standalone: true,
  imports: [NgClass],
  templateUrl: './circle-contact.component.html',
  styleUrl: './circle-contact.component.scss'
})
export class CircleContactComponent implements AfterViewInit
{
  @Input() useDarkTextCircle: boolean = true;;

  circlecolor: string = "#161C2E";

  constructor(private router: Router)
  {
  }
  ngAfterViewInit(): void 
  {
    setTimeout(() => {
      this.circlecolor = this.useDarkTextCircle ? "#161C2E" : "#f5f5f5";
    }, 0);
  }

  onContactUsClick(): void
  {
    this.router.navigate([Route.Contact]);
  }
}
