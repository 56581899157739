import { Component, Input, OnInit } from '@angular/core';
import { TranslatePipe } from '../../pipe/translate.pipe';
import { NgClass, NgIf } from '@angular/common';

import AOS from 'aos';

@Component({
  selector: 'formation',
  standalone: true,
  imports: [TranslatePipe, NgIf, NgClass],
  templateUrl: './formation.component.html',
  styleUrl: './formation.component.scss'
})
export class FormationComponent implements OnInit
{
  @Input() title: string = '';
  @Input() description: string = '';
  @Input() objectives: string = '';
  @Input() target: string = '';
  @Input() duration: string = '';
  @Input() urlImage: string = '';
  @Input() urlProgram: string = '';

  isOpen: boolean = false;

  ngOnInit(): void
  {
    AOS.init();
  }

  onReadToggle(): void
  {
    this.isOpen = !this.isOpen;
  }

  onDownloadClick(): void
  {
    window.open(this.urlProgram, "_blank");
  }
}
