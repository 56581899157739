
<!-- Join Us-->
<section class="bg-dark text-light">

  <div class="d-flex flex-column mt-6">
    <h2>{{ 'join.title' | translate }}</h2>
    <div class="subtitle w-75 mt-3">{{ 'join.subtitle' | translate }}</div>
    <div class="fst-italic ms-2 mt-2">{{ 'join.recruitment-link' | translate }}</div>
    <div class="mt-5">{{ 'join.follow-us' | translate }}</div>
    <img class="social cursor-pointer mt-1" src="../../../assets/images/footer/linkedin.png"  (click)="onLinkedInClick()" />
  </div>

</section>

<!-- Nos valeurs -->
