
<section class="bg-dark text-light justify-content-center align-items-center">

    <!-- Newsletter -->
    <div *ngIf="!isNewsletterRoute" class="d-flex flex-column flex-xl-row justify-content-center align-items-center">
    
        <img class="newsletter mb-2 mb-xl-0" src="../../../assets/images/footer/newsletter.png" />
        <div class="mx-2 mb-2 mb-xl-0">{{ 'footer.newsletter' | translate }}</div>
        <button type="submit" class="input-submit mx-2 mb-2 mb-xl-0" (click)="onNewsletterClick()">{{ 'footer.register' | translate }}</button>
    
    </div>
    <hr *ngIf="!isNewsletterRoute" class="mt-4">

    <!-- Contact -->
    <div class="row w-100 mt-4">

        <div class="col-12 col-xl-3 mb-2 mb-xl-0">
            <div class="d-flex flex-row justify-content-xl-center align-items-center cursor-pointer" (click)="onContactUsClick()">
                <img class="contact" src="../../../assets/images/footer/telegram.png" />
                <div class="ms-2">{{ 'footer.contact' | translate  }}</div>
            </div>
        </div>
        
        <div class="col-12 col-xl-3 mb-2 mb-xl-0">
            <div class="d-flex flex-row justify-content-xl-center align-items-center cursor-pointer" (click)="onJoinUsClick()">
                <img class="contact" src="../../../assets/images/footer/heart.png" />
                <div class="ms-2">{{ 'footer.join' | translate  }}</div>
            </div>
        </div>

        <div class="col-12 col-xl-3 mb-2 mb-xl-0">
            <div class="d-flex flex-row justify-content-xl-center align-items-center cursor-pointer" (click)="onPorfolioClick()">
                <img class="contact" src="../../../assets/images/footer/eye.png" />
                <div class="ms-2">{{ 'footer.portfolio' | translate  }}</div>
            </div>
        </div>

        <div class="col-12 col-xl-3 mb-2 mb-xl-0">
            <div class="d-flex flex-row justify-content-xl-center align-items-center cursor-pointer" (click)="onLinkedInClick()">
                <img class="contact" src="../../../assets/images/footer/linkedin.png" />
                <div class="ms-2">{{ 'footer.linkedin' | translate  }}</div>
            </div>
        </div>
        
    </div>

    <hr class="mt-4">

    <!-- Mentions / Plan-->
    <div class="d-flex flex-row justify-content-center align-items-center mention">
        <div class="typo-footer-small cursor-pointer" (click)="onLegalNoticeClick()">{{ 'footer.terms' | translate }}</div>
        <div class="typo-footer-small">&nbsp;-&nbsp;</div>
        <div class="typo-footer-small">{{ 'footer.copyright' | translate }}</div>
    </div>


</section>