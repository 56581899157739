
<section class="no-padding-btm bg-dark">

  <div class="d-flex flex-column mt-6">
    <div class="typo-nav-title">{{ 'menu.totara.title_nav' | translate }}</div>

    <div class="row">

      <div class="col-12 col-lg-8">
        <h2 class="mt-2">{{ 'totara.title' | translate }}</h2>
      </div>
      <div class="col-12 col-lg-4 my-4 my-lg-0">
        <div class="d-flex flex-row justify-content-center align-items-center h-100">
          <img class="img-fluid" src="../../../assets/images/Totara-Logo.png" />
        </div>
      </div>
    </div>

    <div class="row mt-4">

      <div *ngIf="!leadGotResponse" class="col-12 col-lg-7">
        <div class="text-light">{{ 'totara.subtitle' | translate }}</div>
      </div>

      <div class="col-12 col-lg-5 mt-4 mt-lg-0">
        <div *ngIf="!leadGotResponse" class="text-light">{{ 'totara.toolkit_1' | translate }}</div>
        <form *ngIf="!leadGotResponse" class="d-flex flex-column justify-content-around mt-2" [formGroup]="form" (ngSubmit)="onNewsletterSubmit()">
          <div class="input-line mb-2">
            <!-- Nom -->
            <div class="flex-grow-1">
              <input formControlName="name" type="text" [placeholder]="'global.fields.name' | translate" />
              @if (submitted && f['name'].errors) {
                @if (f['name'].errors['required']) {
                <div class="typo-error">{{ 'global.name_required' | translate }}</div>
                }
              }
            </div>
            <!-- Prenom -->
            <div class="flex-grow-1">
              <input formControlName="surname"  type="text" [placeholder]="'global.fields.surname' | translate" />
            </div>
          </div>
          
          <div class="input-line mb-2">
            <!-- Societe -->
            <div class="flex-grow-1">
              <input formControlName="company" type="text" [placeholder]="'global.fields.company' | translate" />
              @if (submitted && f['company'].errors) {
                @if (f['company'].errors['required']) {
                <div class="typo-error">{{ 'global.company_required' | translate }}</div>
                }
              }
            </div>
            <!-- Email -->
            <div class="flex-grow-1">
              <input formControlName="mail" type="text" [placeholder]="'global.fields.mail' | translate" />
              @if (submitted && f['mail'].errors) {
                <div class="typo-error">
                    @if (f['mail'].errors['required']) {
                    <div>{{ 'global.email_required' | translate }}</div>
                    }
                    @if (f['mail'].errors['email']) {
                    <div>{{ 'global.email_invalid' | translate }}</div>
                    }
                </div>
              }
            </div>
          </div>
                
          <div class="mb-2">
            <div class="input-line">
              <!-- Captcha -->
              <div class="captcha d-flex flex-row align-items-center cursor-pointer" (click)="executeImportantAction()">
                <img [src]="capchaValid ? '../../../assets/images/pictos/check-square.svg' : '../../../assets/images/pictos/square.svg'" />
                <div class="ms-2">{{ "global.iamhuman" | translate }}</div>
              </div>

              <!-- Send -->
              <input class="input submit" type="submit" [value]="'totara.toolkit_2' | translate" [disabled]="leadSend" />
            </div>
            
          @if (submitted && !capchaValid) {
            <div class="typo-error">{{ 'global.capcha_required' | translate }}</div>
          }
          
          </div>

          <div class="">
            <img class="img-fluid" src="../../../assets/images/ourplatforms/Learning-with-Impact-toolkit_Mockup-768x494.png" />
          </div>
        </form>

        <div *ngIf="leadGotResponse" class="text-light mb-10">{{ leadMessageBackend | translate }}</div>


      </div>

    </div>

  </div>

</section>