
export enum Route 
{
    Base = '/',

    Agency = "agency",
    Porfolio = "portfolio",
    OurPlatforms = "platforms",
    Services = "services",
    Resources = "ressources",
    Project = "project",
    Resource = "ressource",
    Totara = "totara",
    
    Newsletter = "newsletter",
    Contact = "contact",
    Join = "join",

    CookiePolicy = "cookiepolicy",
    LegalNotice = "legalnotice",
}