
<!-- Active learning experience -->
<section class="justify-content-center position-relative">
    
    <h1 class="mt-8 mt-xl-0">{{ 'home.section_1.title' | translate }}</h1>
    <div class="text-light w-adj typo-imp mt-3">{{ 'home.section_1.subtitle' | translate }}</div>

    <!-- Active learning experience video -->
    <video class="d-none d-md-block" [autoplay]="true" [muted]="true" [loop]="true" [playsInline]="true" id="background-video">
        <source src="../../../assets/videos/brain.mp4" type="video/mp4" />
    </video>
    <video class="d-block d-md-none" [autoplay]="true" [muted]="true" [loop]="true" [playsInline]="true" id="background-video">
        <source src="../../../assets/videos/brain-mobile.mp4" type="video/mp4" />
    </video>

    <!-- White arrow -->
    <img class="arrow-vertical" src="../../../assets/images/fleche-blanche.svg" />
</section>

<!-- Conseil, Sur mesure, Formation  -->
<section class="bg-light" id="section1">

    <div class="d-flex flex-column">
      <div data-aos="fade-down" class="d-flex flex-row align-items-center mb-4">
          <img src="../../../assets/images/pictos/Picto_star.svg" />
          <div class="color-primary typo-rp-bold ms-3">{{ 'home.section_7.subtitle' | translate }}</div>
      </div>
  
      <div data-aos="fade-right" class="d-flex justify-content-center typo-location mt-4 text-center color-secondary">{{ 'home.section_7.text_1' | translate }}</div>
      <div data-aos="fade-left" class="d-flex justify-content-center typo-location mt-4 text-center color-secondary">{{ 'home.section_7.text_2' | translate }}</div>
      <div data-aos="fade-right" class="d-flex justify-content-center typo-location mt-4 text-center color-secondary">{{ 'home.section_7.text_3' | translate }}</div>
      <div data-aos="fade-left" class="d-flex justify-content-center typo-location mt-4 text-center color-secondary">{{ 'home.section_7.text_4' | translate }}</div>
    </div>
  
    <!-- White arrow -->
    <div class="section-link d-flex flex-row justify-content-center align-items-center cursor-pointer" (click)="onGotoServicesClick()">
        <div class="typo-section-link color-primary me-2">{{ 'home.next.services' | translate }}</div>
        <img class="arrow" src="../../../assets/images/arrow-dark.svg" />
    </div>
  
</section>

<!-- Ils nous font confiance -->
<section class="no-padding bg-dark overflow-hidden">
    
    <h2 data-aos="fade-right">{{ 'home.section_3.title' | translate }}<span class="line"></span></h2>

    <div class="d-flex flex-column">
        <brand-banner class="mt-8" [rightToLeft]="true" [urlBrand]="urlBrandsTop"></brand-banner>
        <brand-banner class="mt-4" [rightToLeft]="false" [urlBrand]="urlBrandsBtw"></brand-banner>
    </div>
    
</section>

<!-- 15ans d'expertise -->
<section class="bg-light position-relative">

    <div class="d-flex flex-column">
        <div data-aos="fade-down" class="d-flex flex-row align-items-center">
            <img src="../../../assets/images/pictos/Picto_star.svg" />
            <div class="color-primary typo-rp-bold ms-3">{{ 'home.section_2.expert' | translate  }}</div>
        </div>

        <h2 data-aos="fade-right" class="color-secondary mt-4">{{ 'home.section_2.title' | translate  }}<span class="line secondary"></span></h2>

        <div class="d-flex flex-row justify-content-around align-items-center text-center flex-wrap mt-4 gap-2">
            <circle-stat [animDelay]="0" data="350">{{ 'home.section_2.keys.customers' | translate }}</circle-stat>
            <circle-stat [animDelay]="600" data="99%">{{ 'home.section_2.keys.satisfaction' | translate }}</circle-stat>
            <circle-stat [animDelay]="150" data="2000">{{ 'home.section_2.keys.projects' | translate }}</circle-stat>
            <circle-stat [animDelay]="300" data="30">{{ 'home.section_2.keys.collaborater' | translate }}</circle-stat>
        </div>
    </div>
    
    <!-- White arrow -->
    <div class="section-link d-flex flex-row justify-content-center align-items-center cursor-pointer" (click)="onGotoAgencyClick()">
        <div class="typo-section-link me-2">{{ 'home.next.agency' | translate }}</div>
        <img class="arrow-horizontal" src="../../../assets/images/arrow-dark.svg" />
    </div>

</section>

<!-- Video teaser -->
<section class="bg-dark position-relative section-small">

    <video #teaserPlayer [autoplay]="true" [muted]="true" [loop]="true" [playsInline]="true" class="video-teaser">
        <source [src]="teaserLink" type="video/mp4" />
    </video>

</section>

<!-- Derniers projets -->
<section class="bg-light position-relative">

    <div class="d-flex flex-column">

        <h2 data-aos="fade-right" class="title-section color-secondary">{{ 'home.section_4.title' | translate  }}<span class="line secondary"></span></h2>

        <div class="row">
            <div class="col-12 col-md-6 col-xl-4" *ngFor="let pjt of lastProjects">
                <project [project]="pjt" [useLatestDesign]="true" (click)="onProjectClick(pjt.id)"></project>
            </div>
        </div>
    </div>
    
    <!-- White arrow -->
    <div class="section-link d-flex flex-row justify-content-center align-items-center cursor-pointer" (click)="onGotoPortfolioClick()">
        <div class="typo-section-link me-2">{{ 'home.next.portfolio' | translate }}</div>
        <img class="arrow-horizontal" src="../../../assets/images/arrow-dark.svg" />
    </div>

</section>

<!-- Dernieres actus -->
<section class="bg-dark position-relative">

    <div class="d-flex flex-column">

        <h2 data-aos="fade-right" class="title-section text-light mb-5">{{ 'home.section_5.title' | translate  }}<span class="line"></span></h2>

        <div class="d-flex justify-content-around flex-wrap">
            <div class="col-12 col-md-5 col-xl-3" *ngFor="let atl of lastArticles">
                <article-latest [article]="atl" (click)="onArticleClick(atl.id)"></article-latest>
            </div>
        </div>
    </div>
    
    <!-- White arrow -->
    <div class="section-link d-flex flex-row justify-content-center align-items-center cursor-pointer" (click)="onGotoResourcesClick()">
        <div class="typo-section-link text-light me-2">{{ 'home.next.articles' | translate }}</div>
        <img class="arrow-horizontal" src="../../../assets/images/arrow-white.svg" />
    </div>

</section>

<!-- Cercle contact -->
<section class="bg-light">
    <div class="d-flex flex-row justify-content-center align-items-center flex-grow-1">
        <circle-contact [useDarkTextCircle]="true">
            <div class="typo-circle-title ">{{ 'home.section_6.title' | translate }}</div>
            <div class="typo-circle-contactus mt-2">{{ 'home.section_6.contact_us' | translate }}</div>
        </circle-contact>
    </div>

    <!-- Back to top-->
    <back-to-top [useBlue]="true"></back-to-top>
</section>
