import { Component, Input, OnInit } from '@angular/core';
import Aos from 'aos';

@Component({
  selector: 'circle-stat',
  standalone: true,
  imports: [],
  templateUrl: './circle-stat.component.html',
  styleUrl: './circle-stat.component.scss'
})
export class CircleStatComponent implements OnInit
{
  @Input() animDelay: number = 0;
  @Input() data: string = '';
  
  ngOnInit(): void
  {
    Aos.init();
  }
}
