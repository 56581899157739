import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom, LOCALE_ID } from '@angular/core';
import { InMemoryScrollingFeature, InMemoryScrollingOptions, provideRouter, withInMemoryScrolling } from '@angular/router';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

import { routes } from './app.routes';
import { I18nService } from './services/i18n.service';
import { provideHttpClient } from '@angular/common/http';
import { APOLLO_OPTIONS, Apollo } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { ApolloClientOptions, ApolloLink, InMemoryCache } from '@apollo/client/core';
import { environment } from '../environments/environment';
import { provideNgcCookieConsent } from 'ngx-cookieconsent';

import { NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { RECAPTCHA_V3_SITE_KEY } from "ng-recaptcha";
import { MarkdownModule } from 'ngx-markdown';

import localeFr from '@angular/common/locales/fr';
import { registerLocaleData } from '@angular/common';
registerLocaleData(localeFr);

const cookieConfig:NgcCookieConsentConfig = {
  cookie: {
    domain: environment.frontendUrl
  },
  palette: {
    popup: {
      background: '#161C2E'
    },
    button: {
      background: '#228CDB'
    }
  },
  theme: 'edgeless',
  type: 'opt-out',
  layout: 'my-custom-layout',
  layouts: {
    "my-custom-layout": '{{messagelink}} {{compliance}}'
  },
  elements:{
    messagelink: `
    <span class="cc-message typo-cookiebanner">
      {{message}} 
      <a class="cc-link" href="{{cookiePolicyHref}}" target="_blank" rel="noopener">{{cookiePolicyLink}}</a>
    </span>
    `,
  },
  content:{    
    cookiePolicyLink: 'Voir plus',
    cookiePolicyHref: environment.frontendUrl + '/cookiepolicy',
  }
};

const graphQLUrl = environment.backendUrl + '/graphql';
const scrollConfig: InMemoryScrollingOptions = {
  scrollPositionRestoration: 'top',
  anchorScrolling: 'disabled',
};
const inMemoryScrollingFeature: InMemoryScrollingFeature = withInMemoryScrolling(scrollConfig);

export const appConfig: ApplicationConfig = {
  providers: [
    {
      // itycom.infos@gmail.com
       provide: RECAPTCHA_V3_SITE_KEY, useValue: "6LccYw0qAAAAAMC-LUNzpZJze16gEU6SOtzeHMGS"
    },
    {
      provide: 'googleTagManagerId',  useValue: 'GTM-MWCLCLZ'
    },
    importProvidersFrom(MarkdownModule.forRoot()),
    provideNgcCookieConsent(cookieConfig),
    provideAnimationsAsync(),
    provideRouter(routes, inMemoryScrollingFeature),
    provideHttpClient(),
    { 
      provide: APP_INITIALIZER, useFactory: (i18nService: I18nService) => () => i18nService.loadDefaultLanguage(), deps: [I18nService], multi: true
    },
    {
      provide: LOCALE_ID,
      deps: [I18nService],      //some service handling global settings
      useFactory: (i18nService: I18nService) => i18nService.Language  //returns locale string
    },
    {
      provide: APOLLO_OPTIONS, useFactory: (httpLink: HttpLink): ApolloClientOptions<unknown> => ({
        link: ApolloLink.from([httpLink.create({ uri: graphQLUrl })]),
        cache: new InMemoryCache(),
        defaultOptions: { query: { fetchPolicy: 'no-cache', errorPolicy: 'all' }}
      }),
      deps: [HttpLink]
    },
    Apollo
  ]
};
