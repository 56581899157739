import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslatePipe } from '../../pipe/translate.pipe';
import { Route } from '../../interface/route';
import { environment } from '../../../environments/environment';
import { Article } from '../../interface/article';
import { Apollo } from 'apollo-angular';
import { ArticlesService } from '../../services/articles.service';
import { DatePipe, NgIf } from '@angular/common';
import { I18nService } from '../../services/i18n.service';
import { Subscription } from 'rxjs';
import { MarkdownModule } from 'ngx-markdown';
import { SeoService } from '../../services/seo.service';

@Component({
  selector: 'app-single-article',
  standalone: true,
  imports: [NgIf, TranslatePipe, DatePipe, MarkdownModule],
  templateUrl: './single-article.component.html',
  styleUrl: './single-article.component.scss'
})
export class SingleArticleComponent implements OnDestroy
{
  article!: Article;
  
  backendUrl: string = environment.backendUrl;
  dateLang: string = 'fr';

  private lgSub: Subscription;

  constructor(private apollo: Apollo, private articlesService: ArticlesService, private activatedRoute: ActivatedRoute, private router: Router, private i18nService: I18nService, private seoService: SeoService)
  {
    let routeId = this.activatedRoute.snapshot.params['id'];
    let variablesArticles = { id: routeId };

    this.lgSub = this.i18nService.onLanguageChange().subscribe((lg) =>
    {
      this.dateLang = lg;
      
      this.apollo.query({ query: this.articlesService.getArticle(), variables: variablesArticles }).subscribe(({data, error}: any) => 
      {
        this.article = data.article.data;
        
        if (this.article.attributes.SEO)
        {
          this.seoService.setMeta(this.article.attributes.SEO.title, this.article.attributes.SEO.description, this.article.attributes.SEO.keywords);
        }
      });
    });
  }

  ngOnDestroy(): void
  {
    this.lgSub.unsubscribe();
  }
  
  onBackClick(): void
  {
    this.router.navigate([Route.Resources]);
  }
}
