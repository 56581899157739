import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Route } from '../../interface/route';
import { TranslatePipe } from '../../pipe/translate.pipe';
import { NgClass } from '@angular/common';
import { I18nService } from '../../services/i18n.service';

@Component({
  selector: 'header',
  standalone: true,
  imports: [NgClass, TranslatePipe],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent
{
  isMenuOpen: boolean = false;

  constructor(public i18nService: I18nService, private router: Router)
  {
  }

  onToggleMenu(): void
  {
    if (this.isMenuOpen)
    {
      this.closeMenu();
    }
    else
    {
      this.openMenu();
    }
  }

  onToggleLang(): void
  {
    this.i18nService.toggleLanguage();
  }

  onLogoClick(): void
  {
    this.router.navigate([Route.Base]);
    this.closeMenu();
  }

  onHomeClick(): void
  {
    this.router.navigate([Route.Base]);
    this.closeMenu();
  }
  onOurplatformsClick(): void
  {
    this.router.navigate([Route.OurPlatforms]);
    this.closeMenu();
  }
  onServicesClick(): void
  {
    this.router.navigate([Route.Services]);
    this.closeMenu();
  }
  onPortfolioClick(): void
  {
    this.router.navigate([Route.Porfolio]);
    this.closeMenu();
  }
  onAgencyClick(): void
  {
    this.router.navigate([Route.Agency]);
    this.closeMenu();
  }
  onArticlesClick(): void
  {
    this.router.navigate([Route.Resources]);
    this.closeMenu();
  }
  onContactClick(): void
  {
    this.router.navigate([Route.Contact]);
    this.closeMenu();
  }

  openMenu(): void
  {
    if (!this.isMenuOpen)
    {
      this.isMenuOpen = true;
      document.body.classList.add('no-scroll');
    }
  }

  closeMenu(): void
  {
    if (this.isMenuOpen)
    {
      this.isMenuOpen = false;
      document.body.classList.remove('no-scroll');
    }
  }
}
