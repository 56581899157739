
<section class="bg-dark">

  <div class="d-flex flex-column mt-6">
    <h2>{{ 'contact.title' | translate }}</h2>

    <div class="row mt-4">

      <div class="col-12 col-lg-6">
        <form *ngIf="!messageCreated" class="d-flex flex-column justify-content-around mb-4" [formGroup]="form" (ngSubmit)="onContactSubmit()">
          <div class="input-line mb-2">
            <!-- Nom -->
            <div class="flex-grow-1">
              <input formControlName="name" type="text" [placeholder]="'global.fields.name' | translate" />
              @if (submitted && f['name'].errors) {
                @if (f['name'].errors['required']) {
                <div class="typo-error">{{ 'global.name_required' | translate }}</div>
                }
              }
            </div>
            <!-- Prenom -->
            <div class="flex-grow-1">
              <input formControlName="surname"  type="text" [placeholder]="'global.fields.surname' | translate" />
            </div>
          </div>
          
          <div class="input-line mb-2">
            <!-- Societe -->
            <div class="flex-grow-1">
              <input formControlName="company" type="text" [placeholder]="'global.fields.company' | translate" />
              @if (submitted && f['company'].errors) {
                @if (f['company'].errors['required']) {
                <div class="typo-error">{{ 'global.company_required' | translate }}</div>
                }
              }
            </div>
            <!-- Telephone -->
            <div class="flex-grow-1">
              <input formControlName="phone" type="text" [placeholder]="'global.fields.phone' | translate" />
            </div>
          </div>
          
          <!-- Email -->
          <div class="mb-2">
            <input formControlName="mail" type="text" [placeholder]="'global.fields.mail' | translate" />
            @if (submitted && f['mail'].errors) {
              <div class="typo-error">
                  @if (f['mail'].errors['required']) {
                  <div>{{ 'global.email_required' | translate }}</div>
                  }
                  @if (f['mail'].errors['email']) {
                  <div>{{ 'global.email_invalid' | translate }}</div>
                  }
              </div>
            }
          </div>
          
          <!-- Message -->
          <div class="mb-2">
            <textarea formControlName="message" name="description" rows="5" [placeholder]="'global.yourmessage' | translate"></textarea>
            @if (submitted && f['message'].errors) {
              <div class="typo-error">
                  @if (f['message'].errors['required']) {
                  <div>{{ 'global.message_required' | translate }}</div>
                  }
              </div>
            }
          </div>
          
          <div class="captcha d-flex flex-row align-items-center cursor-pointer mb-2" (click)="executeImportantAction()">
            <img [src]="capchaValid ? '../../../assets/images/pictos/check-square.svg' : '../../../assets/images/pictos/square.svg'" />
            <div class="ms-2">{{ "global.iamhuman" | translate }}</div>
            @if (submitted && !capchaValid) {
              <div class="typo-error ms-4">{{ 'global.capcha_required' | translate }}</div>
            }
          </div>

          <input class="input submit" type="submit" [value]="'global.send' | translate" />
        </form>

        <div *ngIf="messageCreated" class="text-light mb-10">
          {{ 'global.message_sent' | translate }}
        </div>
      </div>

      <div class="col-12 col-lg-6">

        <div class="container text-optionght">
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="d-flex flex-column text-center text-md-start  mb-4">
                <div class="typo-contact-location">{{ 'global.location.geneva' | translate }}</div>
                <div class="typo-contact-address">Chem. de la Gravière 4,<br>1227 Les Acacias<br>+41 (0)22 566 22 00</div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="d-flex flex-column text-center text-md-start text-center mb-4">
                <div class="typo-contact-location">{{ 'global.location.lyon' | translate }}</div>
                <div class="typo-contact-address">213 rue de Gerland,<br>69007 Lyon<br>+33 (0)4 82 53 02 30</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="d-flex flex-column text-center text-md-start mb-4">
                <div class="typo-contact-location">{{ 'global.location.paris' | translate }}</div>
                <div class="typo-contact-address">54 rue Grenata,<br>75002 Paris<br>+33 (0)1 73 79 23 01</div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="d-flex flex-column text-center text-md-start  mb-4">
                <div class="typo-contact-location">{{ 'global.location.zurich' | translate }}</div>
                <div class="typo-contact-address">Badenerstrasse 549,<br>CH-8048 Zürich<br>+41 (0)44 441 45 50</div>
              </div>
            </div>
          </div>
        </div>  
      </div>

    </div>

    <div class="ceo d-flex flex-column flex-lg-row justify-content-center align-items-center p-4 mb-4">
      <img class="img-ceo mb-4 mb-lg-0" src="../../../assets/images/footer/CEO.png" />
      <div class="typo-contact-ceo ms-lg-4">{{ 'contact.ceo-words' | translate }}<br><br>YANN TEYSSIER</div>
    </div>

  </div>

</section>