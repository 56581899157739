import { Component, Input } from '@angular/core';

@Component({
  selector: 'back-to-top',
  standalone: true,
  imports: [],
  templateUrl: './back-to-top.component.html',
  styleUrl: './back-to-top.component.scss'
})
export class BackToTopComponent 
{
  @Input() useBlue: boolean = false;
  
  onBackToTopClick()
  {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
