import { Component, OnDestroy } from '@angular/core';
import { TranslatePipe } from '../../pipe/translate.pipe';
import { SeoService } from '../../services/seo.service';
import { I18nService } from '../../services/i18n.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-join',
  standalone: true,
  imports: [TranslatePipe],
  templateUrl: './join.component.html',
  styleUrl: './join.component.scss'
})
export class JoinComponent implements OnDestroy
{
  private lgSub: Subscription;
  
  constructor(private seoService: SeoService, private i18nService: I18nService)
  {
    this.lgSub = this.i18nService.onLanguageChange().subscribe(() => {
      this.seoService.setPage("join");
    });
  }
  
  onLinkedInClick(): void
  {
    window.open("https://www.linkedin.com/company/itycom?originalSubdomain=fr", "_blank");
  }
  
  ngOnDestroy(): void
  {
    this.lgSub.unsubscribe();
  }
  
}
