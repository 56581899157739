import { Component, Input } from '@angular/core';
import { Project } from '../../interface/project';
import { NgClass, NgIf } from '@angular/common';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'project',
  standalone: true,
  imports: [NgIf, NgClass],
  templateUrl: './project.component.html',
  styleUrl: './project.component.scss'
})
export class ProjectComponent 
{
  backendUrl: string = environment.backendUrl;

  @Input() project!: Project;
  @Input() useLatestDesign: boolean = false;

  getFirstImageUrl(project: Project): string | undefined
  {
      if (project.attributes.preview)
      {
          return project.attributes.preview.data.attributes.url;
      }
      return undefined;
  }
}
