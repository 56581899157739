
<div class="methodo-list d-flex flex-row mt-2 pt-3">

  <div class="methodo-translate">

    <!-- Analyser -->
    <div class="methodo-item gsap-elem">
      <div class="typo-methodology-title">{{ 'agency.methodology-step-1.methodology-title' | translate }}</div>
      <div class="d-flex flex-row align-items-center mt-3">
        <img class="icon" src="../../../assets/images/methodology/analyser.png" />
        <div class="typo-methodology-description ms-4">{{ 'agency.methodology-step-1.methodology-description' | translate }}</div>
      </div>
    </div>

    <!-- Sequencer -->
    <div class="methodo-item gsap-elem">
      <div class="typo-methodology-title">{{ 'agency.methodology-step-2.methodology-title' | translate }}</div>
      <div class="d-flex flex-row align-items-center mt-3">
        <img class="icon" src="../../../assets/images/methodology/sequencer.png" />
        <div class="typo-methodology-description ms-4">{{ 'agency.methodology-step-2.methodology-description' | translate }}</div>
      </div>
    </div>
    
    <!-- Mixer -->
    <div class="methodo-item gsap-elem">
      <div class="typo-methodology-title">{{ 'agency.methodology-step-3.methodology-title' | translate }}</div>
      <div class="d-flex flex-row align-items-center mt-3">
        <img class="icon" src="../../../assets/images/methodology/mixer.png" />
        <div class="typo-methodology-description ms-4">{{ 'agency.methodology-step-3.methodology-description' | translate }}</div>
      </div>
    </div>

    <!-- Deployer -->
    <div class="methodo-item gsap-elem">
      <div class="typo-methodology-title">{{ 'agency.methodology-step-4.methodology-title' | translate }}</div>
      <div class="d-flex flex-row align-items-center mt-3">
        <img class="icon" src="../../../assets/images/methodology/deployer.png" />
        <div class="typo-methodology-description ms-4">{{ 'agency.methodology-step-4.methodology-description' | translate }}</div>
      </div>
    </div>

    <!-- Evaluer -->
    <div class="methodo-item gsap-elem">
      <div class="typo-methodology-title">{{ 'agency.methodology-step-5.methodology-title' | translate }}</div>
      <div class="d-flex flex-row align-items-center mt-3">
        <img class="icon" src="../../../assets/images/methodology/evaluer.png" />
        <div class="typo-methodology-description ms-4">{{ 'agency.methodology-step-5.methodology-description' | translate }}</div>
      </div>
    </div>

    <!-- Optimiser -->
    <div class="methodo-item gsap-elem">
      <div class="typo-methodology-title">{{ 'agency.methodology-step-6.methodology-title' | translate }}</div>
      <div class="d-flex flex-row align-items-center mt-3">
        <img class="icon" src="../../../assets/images/methodology/optimiser.png" />
        <div class="typo-methodology-description ms-4">{{ 'agency.methodology-step-6.methodology-description' | translate }}</div>
      </div>
    </div>
    
  </div>

</div>
