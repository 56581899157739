import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslatePipe } from '../../pipe/translate.pipe';
import { ProjectComponent } from '../../component/project/project.component';
import { Project } from '../../interface/project';
import { Apollo } from 'apollo-angular';
import { ProjectsService } from '../../services/projects.service';
import { Router } from '@angular/router';
import { Route } from '../../interface/route';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { ActivitiesService } from '../../services/activities.service';
import { Activity } from '../../interface/activity';
import { I18nService } from '../../services/i18n.service';
import { Subscription } from 'rxjs';
import { CircleContactComponent } from '../../component/circle-contact/circle-contact.component';
import { BackToTopComponent } from '../../component/back-to-top/back-to-top.component';
import { SeoService } from '../../services/seo.service';

@Component({
  selector: 'app-portfolio',
  standalone: true,
  imports: [NgFor, NgClass, NgIf, TranslatePipe, ProjectComponent, CircleContactComponent, BackToTopComponent],
  templateUrl: './portfolio.component.html',
  styleUrl: './portfolio.component.scss'
})
export class PortfolioComponent implements OnInit, OnDestroy
{
  projects: Project[] = [];
  activities: Activity[] = [];

  loading : boolean = true;

  activitySelected: Activity | undefined;

  private lgSub: Subscription;

  @ViewChild('activityContainer', { static: false }) activityContainer!: ElementRef;
  
  constructor(  private apollo: Apollo, private projectsService: ProjectsService, private activitiesService: ActivitiesService, private i18nService: I18nService, private router: Router, private seoService: SeoService)
  {
    this.lgSub = this.i18nService.onLanguageChange().subscribe(() => {
      
      this.seoService.setPage("portfolio");

      this.reloadActivities();
      this.reloadProjects();
    });
  }

  ngOnInit(): void
  {
    this.reloadActivities();
    this.reloadProjects();
  }

  ngOnDestroy(): void 
  {
    this.lgSub.unsubscribe();
  }

  reloadActivities(): void
  {
    const activityAll: Activity = { id: 0, attributes: { name: this.i18nService.getText("global.all") } };
    this.activitySelected = activityAll;

    this.apollo.query({ query: this.activitiesService.getActivitiesQuery() }).subscribe(({data, error}: any) =>
    {
      this.activities = [];
      this.activities.push(activityAll);
      this.activities = this.activities.concat(data.activities.data);
    });
  }

  reloadProjects()
  {
    this.loading = true;
    
    let variablesProjects = { activity: "", sort: "order:DESC", limit: 100 };
    if (this.activitySelected && this.activitySelected.attributes.name !== this.i18nService.getText("global.all"))
    {
      variablesProjects.activity = this.activitySelected.attributes.name;
    }

    this.apollo.query({ query: this.projectsService.getProjects(), variables: variablesProjects }).subscribe(({data, error}: any) => 
    {
      this.projects = [];
      this.loading = false;
      this.projects = data.projects.data;
    });
  }

  onProjectClick(projectId: number): void
  {
    this.router.navigate([Route.Project + "/" + projectId]);
  }

  onActivityClick(activity: Activity): void
  {
    this.activitySelected = activity;

    this.reloadProjects();
    this.scrollToSelected();
  }

  onPrevActivityClick(): void
  {
    let index = this.activities.findIndex(act => act.id === this.activitySelected?.id);
    if (index !== -1)
    {
      if (index - 1 >= 0)
      {
        this.activitySelected = this.activities[index - 1];
        this.reloadProjects();
        this.scrollToSelected();
      }
    }
  }

  onNextActivityClick(): void
  {
    let index = this.activities.findIndex(act => act.id === this.activitySelected?.id);
    if (index !== -1)
    {
      if (index + 1 < this.activities.length)
      {
        this.activitySelected = this.activities[index + 1];
        this.reloadProjects();
        this.scrollToSelected();
      }
    }
  }

  scrollToSelected(): void
  {
    // Le setTimeout avec un délai de 0ms permet d'attendre la prochaine boucle d'événements du navigateur, garantissant que les modifications du DOM sont appliquées
    setTimeout(() => {
      if (this.activitySelected)
        {
          const container = this.activityContainer.nativeElement;
          const selectedElement = container.querySelector('.active');
          if (selectedElement) {
            const containerRect = container.getBoundingClientRect();
            const selectedRect = selectedElement.getBoundingClientRect();
            const offset = selectedRect.left - containerRect.left;
            container.scrollTo({
              left: container.scrollLeft + offset - (container.clientWidth / 2 - selectedRect.width / 2),
              behavior: 'smooth'
            });
          }
        }
    }, 0);
  }

  canGoNext(): boolean
  {
    if (this.activitySelected && this.activities.length > 0 && this.activitySelected !== this.activities[0]) return true;
    return false;
  }

  canGoPrev(): boolean
  {
    if (this.activitySelected && this.activities.length > 0 && this.activitySelected !== this.activities[this.activities.length-1]) return true;
    return false;
  }
}
