
import { Injectable } from '@angular/core';
import gql from "graphql-tag";
import { I18nService } from './i18n.service';

@Injectable({
  providedIn: 'root'
})
export class ThematicsService 
{
  constructor(private i18nService: I18nService) {}

  public getThematicsQuery() {
    const currentLanguage = this.i18nService.Language;

    return gql`
      query Thematics {
        thematics(
          locale: "${currentLanguage}",
        ) {
          data {
            id,
            attributes {
              name
            }
          }
        }
      }
    `;
  }
}
