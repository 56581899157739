
<div class="position-relative circle-size cursor-pointer" (click)="onContactUsClick()">

  <div class="position-absolute top-50 start-50 translate-middle text-center" [ngClass]="{ 'text-light': !useDarkTextCircle, 'color-secondary': useDarkTextCircle }">
    <ng-content></ng-content>
  </div>
    
  <svg xmlns="http://www.w3.org/2000/svg" class="circle-size" viewBox="0 0 294.792 296.428">
    <g id="Groupe_347" data-name="Groupe 347" class="anim_rotate">
      <g id="Groupe_335" data-name="Groupe 335">
        <path id="Tracé_344" data-name="Tracé 344" d="M50.361,203.8l-.116-3.748,25.6-.8-.382-12.268,3.109-.1.5,16.018Z" transform="translate(-50.245 -48.446)" [attr.fill]="circlecolor"/>
        <path id="Tracé_345" data-name="Tracé 345" d="M65.952,189.732c5.042.535,7.5-2.383,7.968-6.146a13.409,13.409,0,0,0-.361-5.8l2.747-.349a16.8,16.8,0,0,1,.469,7.013c-.775,6.3-5.425,9.548-11.6,8.789s-10.547-4.989-9.814-10.952c.827-6.724,6.875-7.74,10.639-7.276a9.219,9.219,0,0,1,1.72.339ZM64.63,178.5c-2.32-.329-6.124.234-6.639,4.419-.469,3.806,2.759,5.834,5.3,6.448Z" transform="translate(-46.696 -56.877)" [attr.fill]="circlecolor"/>
        <path id="Tracé_346" data-name="Tracé 346" d="M55,168.907l4.776,1.27,1.379-5.186,2.758.733-1.379,5.188,10.746,2.858c2.47.656,4.055.327,4.592-1.689a6.956,6.956,0,0,0,.31-2.165l2.8.568a10.228,10.228,0,0,1-.36,3.344,5.348,5.348,0,0,1-2.563,3.5c-1.375.694-3.254.634-5.6.01L61.6,174.451l-.821,3.088-2.758-.735.82-3.087-3.705-.985Z" transform="translate(-46.894 -63.87)" [attr.fill]="circlecolor"/>
        <path id="Tracé_347" data-name="Tracé 347" d="M56.341,157.052a52.409,52.409,0,0,1,8.128,7.244l-.59,2.326a63.964,63.964,0,0,0-8.472-5.826Z" transform="translate(-46.609 -69.461)" [attr.fill]="circlecolor"/>
        <path id="Tracé_348" data-name="Tracé 348" d="M75.754,169.319a10.6,10.6,0,0,0,3.4-3.922c1.121-2.439.457-4.15-1.13-4.881-1.626-.748-2.923-.174-4.992,1.876-2.805,2.836-5.353,3.584-7.6,2.551-3.018-1.389-4.355-5.006-2.521-8.992a9.577,9.577,0,0,1,3.262-4.033l2.1,1.953a7.984,7.984,0,0,0-2.886,3.315c-.927,2.011-.264,3.63,1.13,4.271,1.548.712,2.742-.051,4.832-2.044,2.678-2.659,5.088-3.613,7.874-2.332,3.327,1.532,4.455,5.195,2.442,9.567a11.757,11.757,0,0,1-3.738,4.658Z" transform="translate(-41.911 -73.077)" [attr.fill]="circlecolor"/>
        <path id="Tracé_349" data-name="Tracé 349" d="M68.861,139.839l4.022,2.872L76,138.343,78.325,140l-3.119,4.368,9.048,6.461c2.079,1.486,3.678,1.738,4.892.039a6.969,6.969,0,0,0,1.055-1.916l2.422,1.519a10.213,10.213,0,0,1-1.515,3,5.348,5.348,0,0,1-3.635,2.376c-1.531.164-3.267-.554-5.244-1.965l-9.151-6.536-1.858,2.6L68.9,148.291l1.858-2.6-3.121-2.229Z" transform="translate(-37.997 -82.639)" [attr.fill]="circlecolor"/>
        <path id="Tracé_350" data-name="Tracé 350" d="M95.734,139a34.65,34.65,0,0,0,3.809,3.162l-2.3,2.456-2.1-1.559-.087.092a7.68,7.68,0,0,1-2.073,6.7c-2.855,3.047-6.456,2.591-8.631.552-3.637-3.409-2.6-8.5,2.882-14.288l-.312-.291c-1.212-1.135-3.8-2.92-6.652.186a9.932,9.932,0,0,0-2.582,5l-2.415-1.1a13.352,13.352,0,0,1,3.354-6.2c4.312-4.6,8.5-2.776,11.482.02Zm-6.546-1.174c-2.858,2.926-5.505,6.809-2.584,9.546a3.283,3.283,0,0,0,5-.1,5.2,5.2,0,0,0,.949-6.057,4.224,4.224,0,0,0-.753-.941Z" transform="translate(-32.616 -87.877)" [attr.fill]="circlecolor"/>
        <path id="Tracé_351" data-name="Tracé 351" d="M80.3,123.1l2.865-2.417,19.5,23.118L99.8,146.222Z" transform="translate(-29.076 -95.076)" [attr.fill]="circlecolor"/>
        <path id="Tracé_352" data-name="Tracé 352" d="M99.724,132.1l.072-.048c.026-.885.1-2.008.2-2.94l1.145-9.294,3.726-2.471-1.919,11.441,14.271,5.1-3.835,2.541L102.028,132.1l-.421,2.834,4.283,6.461-3.088,2.047-16.71-25.2,3.088-2.049Z" transform="translate(-24.996 -98.242)" [attr.fill]="circlecolor"/>
        <path id="Tracé_353" data-name="Tracé 353" d="M111.789,127.929l-8.542-18.63,3.765-1.6,7.445,19.1Zm1.379,5.986a2.507,2.507,0,0,1,1.323-3.429,2.441,2.441,0,0,1,3.3,1.467,2.516,2.516,0,1,1-4.627,1.962Z" transform="translate(-12.912 -104.221)" [attr.fill]="circlecolor"/>
        <path id="Tracé_354" data-name="Tracé 354" d="M140.211,125.714a14.468,14.468,0,0,1-5.679,2.059c-6.151.922-10.778-2.649-11.714-8.883-.943-6.277,2.666-11.515,9.323-12.513a11.808,11.808,0,0,1,5.3.324l-.419,2.95a9.2,9.2,0,0,0-4.443-.326c-4.675.7-6.679,4.578-6.047,8.791.709,4.719,4.177,7.17,8.222,6.564a10.717,10.717,0,0,0,4.406-1.652Z" transform="translate(0.769 -105.262)" [attr.fill]="circlecolor"/>
        <path id="Tracé_355" data-name="Tracé 355" d="M154.185,116.391c-.124,7.625-5.505,10.86-10.446,10.778-5.537-.092-9.815-4.252-9.708-10.772.114-6.857,4.738-10.83,10.4-10.736C150.355,105.759,154.289,110.127,154.185,116.391Zm-16.318-.056c-.075,4.515,2.425,7.965,6.09,8.026,3.577.06,6.316-3.262,6.393-7.9.058-3.494-1.617-7.91-6.047-7.983C139.916,108.4,137.93,112.457,137.867,116.334Z" transform="translate(8.77 -105.66)" [attr.fill]="circlecolor"/>
        <path id="Tracé_356" data-name="Tracé 356" d="M150.6,111.955c.412-2.134.694-3.816.891-5.515l3.264.632-.438,3.388.085.015A7.637,7.637,0,0,1,161.829,108c2.8.542,6.833,3.056,5.488,10l-2.328,12.052-3.681-.712,2.255-11.675c.631-3.264-.056-6.218-3.53-6.889a5.5,5.5,0,0,0-6.248,4.479l-2.354,12.176-3.681-.712Z" transform="translate(18.434 -105.111)" [attr.fill]="circlecolor"/>
        <path id="Tracé_357" data-name="Tracé 357" d="M172.384,108.033l-1.633,4.668,5.07,1.773-.942,2.695-5.071-1.773-3.671,10.5c-.844,2.413-.639,4.021,1.333,4.711a7.011,7.011,0,0,0,2.136.476l-.781,2.753a10.235,10.235,0,0,1-3.308-.615,5.344,5.344,0,0,1-3.3-2.826c-.586-1.423-.382-3.295.419-5.587l3.714-10.622-3.017-1.055.943-2.695,3.018,1.055,1.265-3.622Z" transform="translate(28.387 -104.104)" [attr.fill]="circlecolor"/>
        <path id="Tracé_358" data-name="Tracé 358" d="M182.866,133.19a34.6,34.6,0,0,0-1.975,4.539l-3-1.525.914-2.453-.114-.058a7.676,7.676,0,0,1-7.012-.126c-3.721-1.894-4.285-5.48-2.933-8.137,2.26-4.442,7.446-4.863,14.528-1.212l.193-.38c.753-1.481,1.747-4.465-2.032-6.34a9.94,9.94,0,0,0-5.517-1.086l.38-2.626a13.344,13.344,0,0,1,6.886,1.495c5.621,2.862,5.036,7.392,3.18,11.037Zm-.7-6.618c-3.608-1.929-8.074-3.39-9.89.179a3.284,3.284,0,0,0,1.49,4.774,5.21,5.21,0,0,0,6.085-.775,4.283,4.283,0,0,0,.694-.987Z" transform="translate(32.752 -99.961)" [attr.fill]="circlecolor"/>
        <path id="Tracé_359" data-name="Tracé 359" d="M189.281,142.986a14.51,14.51,0,0,1-5.509-2.487c-5.053-3.63-5.88-9.418-2.2-14.542,3.7-5.157,9.945-6.395,15.414-2.466a11.784,11.784,0,0,1,3.576,3.934l-2.357,1.822a9.191,9.191,0,0,0-2.959-3.332c-3.843-2.761-7.983-1.379-10.47,2.083-2.785,3.877-2.008,8.055,1.316,10.443a10.758,10.758,0,0,0,4.31,1.888Z" transform="translate(40.657 -94.73)" [attr.fill]="circlecolor"/>
        <path id="Tracé_360" data-name="Tracé 360" d="M206.411,127.533l-3.385,3.6,3.913,3.676-1.955,2.081-3.913-3.676-7.615,8.1c-1.75,1.865-2.216,3.416-.694,4.845a6.973,6.973,0,0,0,1.759,1.3l-1.83,2.2a10.212,10.212,0,0,1-2.771-1.9,5.341,5.341,0,0,1-1.871-3.92c.043-1.539.987-3.165,2.65-4.936l7.7-8.2-2.33-2.188,1.955-2.079,2.33,2.188,2.626-2.8Z" transform="translate(46.819 -91.453)" [attr.fill]="circlecolor"/>
        <path id="Tracé_361" data-name="Tracé 361" d="M199.83,141.022c-3.879,3.264-3.484,7.059-1.093,10a13.4,13.4,0,0,0,4.425,3.758L201.51,157a16.8,16.8,0,0,1-5.377-4.522c-4-4.926-3.121-10.526,1.7-14.448s10.9-4.143,14.693.518c4.271,5.255.811,10.318-2.13,12.708a8.961,8.961,0,0,1-1.437,1Zm9.014,6.836c1.844-1.444,4.075-4.575,1.415-7.847-2.417-2.974-6.115-2.055-8.319-.648Z" transform="translate(50.733 -84.944)" [attr.fill]="circlecolor"/>
        <path id="Tracé_362" data-name="Tracé 362" d="M200.542,152.3l15.172,1.865c1.467.227,2.744.467,4.174.714l.073-.043-5.648-9.628,2.572-1.51,7.934,13.524-2.021,1.185-14.983-1.878c-1.444-.189-2.773-.349-4.13-.641l-.073.044,6.081,10.363-2.574,1.508-8.452-14.4Z" transform="translate(54.298 -78.864)" [attr.fill]="circlecolor"/>
        <path id="Tracé_363" data-name="Tracé 363" d="M216,165.188l-2.5,1.086-4.188-9.655,2.5-1.084Z" transform="translate(61.797 -70.531)" [attr.fill]="circlecolor"/>
        <path id="Tracé_364" data-name="Tracé 364" d="M221.611,164.376c2.1-.574,3.729-1.064,5.338-1.636l.876,3.2-3.232,1.1.024.082a7.636,7.636,0,0,1,5.495,5.565c.753,2.753.278,7.475-6.543,9.34l-11.835,3.235-.989-3.615,11.465-3.134c3.2-.878,5.549-2.8,4.617-6.209a5.493,5.493,0,0,0-6.775-3.623L208.1,171.957l-.99-3.617Z" transform="translate(60.242 -65.455)" [attr.fill]="circlecolor"/>
        <path id="Tracé_365" data-name="Tracé 365" d="M222.254,197.445c-7.6.651-11.363-4.37-11.786-9.294-.472-5.517,3.23-10.2,9.723-10.753,6.835-.586,11.256,3.61,11.74,9.255C232.438,192.552,228.494,196.91,222.254,197.445Zm-1.607-16.238c-4.5.385-7.675,3.223-7.363,6.874.307,3.564,3.889,5.952,8.515,5.555,3.48-.3,7.7-2.415,7.324-6.829C228.748,182.437,224.509,180.875,220.647,181.208Z" transform="translate(62.583 -55.168)" [attr.fill]="circlecolor"/>
        <path id="Tracé_366" data-name="Tracé 366" d="M215.865,209.186c-2.163-.2-4.033-.332-5.657-.356l.309-3.31,3.37.1.008-.085a7.7,7.7,0,0,1-3.185-7.143c.3-3.223,2.485-6.913,9.654-6.245l12,1.118L232.017,197l-11.324-1.055c-3.9-.365-6.69.617-7,3.968a5.391,5.391,0,0,0,2.978,5.241,5.133,5.133,0,0,0,1.877.516L231.1,206.84l-.348,3.733Z" transform="translate(62.428 -44.796)" [attr.fill]="circlecolor"/>
        <path id="Tracé_367" data-name="Tracé 367" d="M214.144,205.65a10.58,10.58,0,0,0-2.63,4.476c-.661,2.6.305,4.165,2,4.6,1.735.44,2.906-.363,4.568-2.754,2.245-3.3,4.614-4.5,7.01-3.891,3.221.816,5.193,4.131,4.114,8.385a9.572,9.572,0,0,1-2.476,4.557l-2.422-1.537a8,8,0,0,0,2.234-3.785c.545-2.147-.4-3.618-1.887-4-1.653-.419-2.689.549-4.382,2.887-2.151,3.1-4.346,4.481-7.32,3.727-3.552-.9-5.326-4.3-4.142-8.965a11.729,11.729,0,0,1,2.829-5.26Z" transform="translate(61.185 -36.328)" [attr.fill]="circlecolor"/>
        <path id="Tracé_368" data-name="Tracé 368" d="M208,218.743a2.507,2.507,0,0,1,1.365,3.414,2.442,2.442,0,0,1-3.424,1.173A2.517,2.517,0,1,1,208,218.743Zm5.086,3.45,18.946,7.849-1.675,3.733L211.9,224.836Z" transform="translate(58.288 -26.196)" [attr.fill]="circlecolor"/>
        <path id="Tracé_369" data-name="Tracé 369" d="M205.23,246.117l-.058.063c.187.868.382,1.975.51,2.906l1.1,9.306-3.034,3.291-.862-11.579-15.085-1.56,3.121-3.385,12.067,1.5-.268-2.855-5.7-5.258,2.514-2.727,22.249,20.514-2.512,2.727Z" transform="translate(46.646 -13.979)" [attr.fill]="circlecolor"/>
        <path id="Tracé_370" data-name="Tracé 370" d="M183.279,262.47c-4.673-6.028-2.5-11.919,1.406-14.949,4.379-3.4,10.32-2.824,14.315,2.328,4.2,5.423,3.08,11.416-1.4,14.889C192.92,268.367,187.117,267.419,183.279,262.47Zm12.767-10.17c-2.766-3.569-6.875-4.7-9.773-2.451-2.829,2.194-2.887,6.5-.041,10.17,2.141,2.761,6.211,5.159,9.713,2.444C199.414,259.773,198.422,255.366,196.046,252.3Z" transform="translate(41.594 -7.241)" [attr.fill]="circlecolor"/>
        <path id="Tracé_371" data-name="Tracé 371" d="M190.025,264.943c1,1.929,1.824,3.422,2.724,4.876l-2.95,1.531-1.759-2.926-.075.039a7.641,7.641,0,0,1-4.283,6.545c-2.534,1.316-7.252,1.846-10.511-4.433l-5.655-10.889,3.327-1.728,5.478,10.55c1.532,2.95,3.9,4.837,7.042,3.208a5.493,5.493,0,0,0,2.117-7.388l-5.713-11,3.327-1.728Z" transform="translate(32.356 -2.869)" [attr.fill]="circlecolor"/>
        <path id="Tracé_372" data-name="Tracé 372" d="M172.681,282.394l-1.619-4.67-5.072,1.759-.937-2.7,5.074-1.761-3.646-10.507c-.837-2.415-2-3.547-3.969-2.862a7,7,0,0,0-1.97.953l-1.1-2.64a10.188,10.188,0,0,1,2.972-1.573,5.348,5.348,0,0,1,4.341.162c1.343.752,2.347,2.344,3.143,4.639l3.687,10.627,3.02-1.046.934,2.7-3.018,1.047,1.258,3.623Z" transform="translate(26.672 1.657)" [attr.fill]="circlecolor"/>
        <path id="Tracé_373" data-name="Tracé 373" d="M150.258,267.321a34.512,34.512,0,0,0-1.273-4.784l3.3-.668.81,2.488.124-.026a7.681,7.681,0,0,1,5.573-4.258c4.092-.828,6.765,1.629,7.356,4.554.989,4.886-2.814,8.438-10.634,9.977l.085.418c.331,1.629,1.406,4.585,5.532,3.707a9.943,9.943,0,0,0,5-2.577l1.334,2.3a13.369,13.369,0,0,1-6.328,3.105c-6.182,1.251-8.539-2.664-9.35-6.672Zm4.658,4.753c4.026-.728,8.435-2.359,7.641-6.286a3.286,3.286,0,0,0-4.135-2.816,5.21,5.21,0,0,0-4.286,4.39,4.277,4.277,0,0,0,.07,1.2Z" transform="translate(19.304 2.989)" [attr.fill]="circlecolor"/>
        <path id="Tracé_374" data-name="Tracé 374" d="M150.179,273.071l-.085,0c-.479.747-1.121,1.67-1.686,2.42l-5.774,7.377-4.471.2,7.547-8.822-9.6-11.733,4.6-.2,7.5,9.565,1.822-2.212-.341-7.748,3.705-.164,1.328,30.227-3.7.164Z" transform="translate(10.234 4.286)" [attr.fill]="circlecolor"/>
        <path id="Tracé_375" data-name="Tracé 375" d="M133.193,287.446l.423-4.922-5.347-.458.244-2.845,5.347.458.949-11.077c.22-2.546-.378-4.051-2.458-4.23a7.062,7.062,0,0,0-2.187.068l.075-2.857a10.2,10.2,0,0,1,3.354-.227,5.347,5.347,0,0,1,3.9,1.916c.92,1.234,1.188,3.1.982,5.515l-.961,11.2,3.184.273-.245,2.845-3.182-.274-.329,3.821Z" transform="translate(4.712 3.977)" [attr.fill]="circlecolor"/>
        <path id="Tracé_376" data-name="Tracé 376" d="M122.116,286.6a2.239,2.239,0,0,1,2.756-1.842,2.205,2.205,0,0,1,1.817,2.666,2.285,2.285,0,0,1-2.722,1.892A2.25,2.25,0,0,1,122.116,286.6Zm8.786-25.269-3.649,20.3-3.692-.663,3.651-20.3Z" transform="translate(0.348 3.521)" [attr.fill]="circlecolor"/>
        <path id="Tracé_377" data-name="Tracé 377" d="M125.374,270.306c1.413-4.871-1.026-7.809-4.65-8.927a13.416,13.416,0,0,0-5.771-.665l.138-2.766a16.764,16.764,0,0,1,6.988.772c6.068,1.871,8.449,7.02,6.615,12.967s-6.768,9.509-12.51,7.738c-6.477-2-6.412-8.133-5.294-11.757a9.121,9.121,0,0,1,.637-1.633Zm-11.3-.675c-.731,2.228-.847,6.073,3.185,7.315,3.664,1.13,6.229-1.691,7.281-4.087Z" transform="translate(-7.933 1.538)" [attr.fill]="circlecolor"/>
        <path id="Tracé_378" data-name="Tracé 378" d="M112.155,269.768c-1.024,2.2-1.868,4.111-2.563,5.9l-2.972-1.387,1.573-3.779-.116-.054a6.238,6.238,0,0,1-7.123,1.665,7.675,7.675,0,0,1-.89-.51l1.493-3.206a5.819,5.819,0,0,0,1.123.617c2.395,1.116,4.921.131,6.572-2.2a11.109,11.109,0,0,0,.854-1.529l4.644-9.964,3.4,1.583Z" transform="translate(-15.154 -0.242)" [attr.fill]="circlecolor"/>
        <path id="Tracé_379" data-name="Tracé 379" d="M106.195,263.245c2.661-4.317,1.094-7.8-2.1-9.841a13.4,13.4,0,0,0-5.382-2.18l.871-2.63a16.8,16.8,0,0,1,6.528,2.609c5.347,3.421,6.267,9.018,2.914,14.257s-9.057,7.356-14.117,4.118c-5.706-3.651-4.009-9.544-1.965-12.738a9.12,9.12,0,0,1,1.05-1.4ZM95.49,259.58c-1.3,1.952-2.437,5.624,1.116,7.9,3.23,2.067,6.454.032,8.106-2Z" transform="translate(-21.525 -4.982)" [attr.fill]="circlecolor"/>
        <path id="Tracé_380" data-name="Tracé 380" d="M85.42,239.928c1.575-1.5,2.9-2.824,3.988-4.033L91.7,238.3l-2.291,2.475.058.061a7.7,7.7,0,0,1,7.48,2.3c2.234,2.345,3.581,6.419-1.636,11.385l-8.737,8.317-2.586-2.719,8.241-7.845c2.841-2.7,3.934-5.451,1.612-7.89a5.4,5.4,0,0,0-5.909-1.2,5.112,5.112,0,0,0-1.624,1.075l-9.137,8.7-2.587-2.717Z" transform="translate(-33.101 -13.927)" [attr.fill]="circlecolor"/>
        <path id="Tracé_381" data-name="Tracé 381" d="M79.065,248.008c-1.817,1.191-3.22,2.161-4.574,3.2l-1.82-2.78,2.732-2.045-.046-.072a7.637,7.637,0,0,1-6.944-3.6c-1.565-2.388-2.568-7.027,3.346-10.9l10.262-6.727,2.054,3.136-9.94,6.516c-2.778,1.822-4.418,4.373-2.48,7.331a5.5,5.5,0,0,0,7.564,1.358l10.368-6.8,2.055,3.136Z" transform="translate(-38.296 -21.542)" [attr.fill]="circlecolor"/>
        <path id="Tracé_382" data-name="Tracé 382" d="M80.771,228.25a10.593,10.593,0,0,0-.729-5.139c-1.1-2.447-2.834-3.068-4.426-2.349-1.631.736-2.047,2.091-1.858,5,.3,3.978-.806,6.395-3.058,7.411-3.029,1.367-6.635,0-8.442-4a9.6,9.6,0,0,1-.9-5.108l2.853-.307a7.987,7.987,0,0,0,.61,4.353c.912,2.02,2.567,2.58,3.964,1.95,1.553-.7,1.761-2.1,1.628-4.989-.251-3.767.608-6.211,3.4-7.472,3.341-1.508,6.843.043,8.824,4.43a11.733,11.733,0,0,1,1.064,5.875Z" transform="translate(-42.467 -27.207)" [attr.fill]="circlecolor"/>
        <path id="Tracé_383" data-name="Tracé 383" d="M73.338,212.152l-19.7,5.657-1.014-3.963,20-4.5Zm6.124-.467a2.507,2.507,0,0,1-3.192-1.822,2.442,2.442,0,0,1,1.946-3.049,2.517,2.517,0,1,1,1.246,4.871Z" transform="translate(-48.572 -34.481)" [attr.fill]="circlecolor"/>
      </g>
    </g>
  </svg>

</div>
