
<div class="d-flex flex-column article">

  <img class="pic mb-3" [src]="backendUrl + article.attributes.preview.data.attributes.url" />
  
  <div class="d-flex flex-column justify-content-start align-items-start article-white p-1">
    <div class="fst-italic" *ngIf="article.attributes.thematic.data">{{ article.attributes.thematic.data.attributes.name }}</div>
  
    <div class="title">{{ article.attributes.name }}</div>
    <div class="description mt-3">{{ article.attributes.description }}</div>
    
    <div><img class="eye mt-3" src="../../../assets/images/pictos/eye.svg" /></div>
  </div>

  <hr class="my-3">
  
</div>
