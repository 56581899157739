
import { Injectable } from '@angular/core';
import gql from "graphql-tag";
import { I18nService } from './i18n.service';

@Injectable({
  providedIn: 'root'
})
export class ContactService 
{
  constructor() {}

  public createEmail()
  {
    return gql`
    mutation CreateEmail($name: String!, $surname: String!, $email: String!, $company: String!, $phone: String!, $message: String!) {
      createEmail(data: { name: $name, surname: $surname, mail: $email, company: $company, place: "All", phone: $phone, message: $message }) {
        data {
          id
          attributes {
            name
          }
        }
      }
    }
    `;
  }
}
