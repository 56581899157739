
<ng-container *ngIf="project">

  <section class="justify-content-start bg-dark">

    <div class="d-flex flex-column text-light">
  
      <div class="d-flex flex-row align-items-center cursor-pointer mt-6" (click)="onBackClick()">  
        <img class="arrow-back active me-3" src="../../../assets/images/pictos/arrow.svg"  />
        <div class="typo-project-backbtn">{{ 'project.back_to_portfolio' | translate }}</div>
      </div>
  
      <h2 class="mt-4">{{ project.attributes.company }}</h2>
    
      <div class="typo-project-name mt-4">{{ project.attributes.name }}</div>
      <div class="typo-project-description mt-4">{{ project.attributes.description }}</div>
  
      <div id="carouselExampleControls" class="carousel d-flex justify-content-center slide my-4" data-bs-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item text-center" [ngClass]="{ 'active' : i == 0 }" *ngFor="let carou of project.attributes.carousel.data; let i=index">
            <img [src]="backendUrl + carou.attributes.url" class="img-fluid" alt="">
          </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
      
      <markdown class="typo-markdown mt-4">{{ project.attributes.needs }}</markdown>
  
    </div>
  
  </section>
  
  <section class="bg-light container-ity py-4 no-min-height">
  
    <div class="d-flex flex-column">
  
      <!-- Solutions / Bénéfices -->
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="d-flex flex-column mb-4 mb-md-0">
  
            <markdown class="typo-markdown">{{ project.attributes.left_content }}</markdown>
  
          </div>
        </div>
  
        <div class="col-12 col-md-6">
          <div class="d-flex flex-column">
  
            <markdown class="typo-markdown">{{ project.attributes.right_content }}</markdown>
  
          </div>
        </div>
      </div>
  
      <!-- Autres projets 
      <hr class="mt-4">
  
      <div class="color-primary mt-4 d-flex justify-content-center text-center">
        {{ 'project.otherprojects' | translate }}
      </div> -->
  
    </div>
  
  </section>

</ng-container>