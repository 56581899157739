import { Component } from '@angular/core';
import { TranslatePipe } from '../../pipe/translate.pipe';
import { Router } from '@angular/router';
import { Route } from '../../interface/route';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgIf } from '@angular/common';

@Component({
  selector: 'footer',
  standalone: true,
  imports: [TranslatePipe, ReactiveFormsModule, NgIf],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent 
{
  isNewsletterRoute: boolean = false;
  
  constructor(private router: Router)
  {
    this.router.events.subscribe(() => {
      const currentRoute = this.router.url;
      this.isNewsletterRoute = currentRoute === '/' + Route.Newsletter;
    });
  }

  onNewsletterClick(): void
  {
    this.router.navigate([Route.Newsletter]);
  }

  onContactUsClick(): void
  {
    this.router.navigate([Route.Contact]);
  }
  onJoinUsClick(): void
  {
    this.router.navigate([Route.Join]);
  }
  onPorfolioClick(): void
  {
    this.router.navigate([Route.Porfolio]);
  }

  onYoutubeClick(): void
  {
    window.open("https://www.youtube.com/@Itystore", "_blank");
  }
  onTwitterClick(): void
  {
    window.open("https://twitter.com/x/migrate?tok=eyJlIjoiL0lUeWNvbV9GUiIsInQiOjE3MTYzMDc4MjR9agjH5XTtlZUWju2teAJSQg%3D%3D", "_blank");
  }
  onLinkedInClick(): void
  {
    window.open("https://www.linkedin.com/company/itycom?originalSubdomain=fr", "_blank");
  }

  onLegalNoticeClick(): void
  {
    this.router.navigate([Route.LegalNotice]);
  }
}
