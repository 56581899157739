import { Component, OnDestroy } from '@angular/core';
import { TranslatePipe } from '../../pipe/translate.pipe';
import { SeoService } from '../../services/seo.service';
import { I18nService } from '../../services/i18n.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-cookiepolicy',
  standalone: true,
  imports: [TranslatePipe],
  templateUrl: './cookiepolicy.component.html',
  styleUrl: './cookiepolicy.component.scss'
})
export class CookiepolicyComponent implements OnDestroy
{
  private lgSub: Subscription;
  
  constructor(private seoService: SeoService, private i18nService: I18nService)
  {
    this.lgSub = this.i18nService.onLanguageChange().subscribe(() => {
      this.seoService.setPage("cookiepolicy");
    });
  }

  ngOnDestroy(): void {
    this.lgSub.unsubscribe();
  }
}
