
import { Injectable } from '@angular/core';
import gql from "graphql-tag";
import { I18nService } from './i18n.service';

@Injectable({
  providedIn: 'root'
})
export class ArticlesService 
{
  constructor(private i18nService: I18nService) 
  {
  }

  public getArticle() {
    const currentLanguage = this.i18nService.Language;
    return gql`
    query Article($id: ID!) {
      article(
          locale: "${currentLanguage}",
          id: $id
        ) {
        data {
          id,
          attributes {
            publishedAt,
            name,
            description,
            order,
            content,
            preview {
              data {
                id,
                attributes {
                  url
                }
              }
            },
            media {
              data {
                id,
                attributes {
                  mime,
                  url
                }
              }
            },
            type {
              data {
                id, 
                attributes{name}
              }
            },
            thematic {
              data {
                id, 
                attributes{name}
              }
            },
            SEO
            {
              title,
              description,
              keywords
            }
          }
        }
      }
    }
    `;
  }

  public getArticles() {
    const currentLanguage = this.i18nService.Language;
    return gql`
    query Articles($thematic: String, $type: String, $id: ID, $limit: Int, $sort: [String]) {
      articles(
              locale: "${currentLanguage}",
              pagination: {limit: $limit},
              sort: $sort,
              filters: {
                id: {
                  notContains: $id
                }
                type: {
                  name: {
                    contains: $type
                  }
                }
                thematic: {
                  name: {
                    contains: $thematic
                  }
                }
              }) {
        data {
          id,
          attributes {
            name,
            description,
            content
            preview {
              data {
                id,
                attributes {
                  url
                }
              }
            }
            type 
              {data {
                id, 
                attributes{
                  name,
                  icon {
                    data {
                      id,
                      attributes {
                        url
                      }
                    }
                  }
                }
              }
            },
            thematic {
              data {
                id, 
                attributes{name}
              }
            }
          }
        }
      }
    }
    `;
  }
}
