
<div data-aos="flip-up" class="formation d-flex flex-column flex-xl-row mt-4">

  <img class="formation-img" [src]="urlImage" />

  <div class="d-flex flex-column justify-content-between p-3">

    <!-- Formation / durée -->
    <div class="d-flex flex-row justify-content-between">
      <div class="typo-formation-title">{{ title }}</div>
      
      <div class="d-flex flex-row no-wrap typo-formation-duration">
        <div class="text-nowrap">{{ 'services.section_5.duration' | translate }}</div>
        <div class="ms-2 text-nowrap">{{ duration }}</div>
      </div>
    </div>

    <!-- Description -->
    <div class="d-flex mt-4">{{ description }}</div>

    <!-- Objectifs / Public -->
    <div class="d-flex flex-row justify-content-between mt-4">

      <div class="expand-content column-equal" [ngClass]="{ 'active': isOpen }">
        <div class="typo-formation-duration">{{ 'services.section_5.goals' | translate }}</div>
        <div class="">{{ objectives }}</div>
      </div>
      
      <div class="expand-content column-equal" [ngClass]="{ 'active': isOpen }">
        <div class="typo-formation-duration">{{ 'services.section_5.target' | translate }}</div>
        <div class="">{{ target }}</div>
      </div>

    </div>

    <!-- Lire Plus / Telecharger -->
    <div class="d-flex flex-row justify-content-between align-items-center mt-4">

      <div *ngIf="!isOpen" class="typo-formation-links column-equal" (click)="onReadToggle()">{{ 'global.readmore' | translate }}</div>
      <div *ngIf="isOpen" class="typo-formation-links column-equal" (click)="onReadToggle()">{{ 'global.readless' | translate }}</div>
      <div class="d-flex flex-row justify-content-end align-items-center column-equal" (click)="onDownloadClick()">
        <img src="../../../assets/formation/download.svg" />
        <div class="ms-2 typo-formation-links">{{ 'services.section_5.download' | translate }}</div>
      </div>

    </div>

  </div>
</div>